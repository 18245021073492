///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/site
export const getSitesSuccess = sites => ({ type: types.GET_SITES_SUCCESS, sites });
export const getSitesFailure = errorMessage => ({ type: types.GET_SITES_FAILURE, errorMessage });
export const getSites = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/site` : `${resolveHost()}/api/site${endpoint}`);

    dispatch(getSitesSuccess(res.data));
  } catch (e) {
    dispatch(getSitesFailure(e.message));
  }
};

// POST /api/site
export const postSiteSuccess = site => ({ type: types.POST_SITE_SUCCESS, site });
export const postSiteFailure = errorMessage => ({ type: types.POST_SITE_FAILURE, errorMessage });
export const postSite = data => async dispatch => {
  try {
    dispatch(setAlert('Creating the site...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/site`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_SITE_SUCCESS, false));
    dispatch(postSiteSuccess(res.data.site));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.POST_SITE_FAILURE, false));
    dispatch(postSiteFailure(e.message));
  }
};

// PUT /api/site
export const putSiteSuccess = site => ({ type: types.PUT_SITE_SUCCESS, site });
export const putSiteFailure = errorMessage => ({ type: types.PUT_SITE_FAILURE, errorMessage });
export const putSite = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the site...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/site/${data.siteId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_SITE_SUCCESS));
    dispatch(putSiteSuccess(res.data.site));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_SITE_FAILURE));
    dispatch(putSiteFailure(e.message));
  }
};

// DELETE /api/site
export const deleteSiteSuccess = site => ({ type: types.DELETE_SITE_SUCCESS, site });
export const deleteSiteFailure = errorMessage => ({ type: types.DELETE_SITE_FAILURE, errorMessage });
export const deleteSite = siteId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the site...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/site/${siteId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_SITE_SUCCESS));
    dispatch(deleteSiteSuccess(res.data.site));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.DELETE_SITE_FAILURE));
    dispatch(deleteSiteFailure(e.message));
  }
};
