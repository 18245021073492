///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useContext, useEffect } from "react";
import { isEqual } from "lodash";
import { FormGroup, Label, CustomInput } from "@ibiliaze/reactstrap";
import Select from "react-select";
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import ConfigContext from "../../context/ObjectContext";
//////////////////////////////////////////////////////////////////////////////////////////

const BlockModuleInputs = ({ module }) => {
  const [inputs, setInputs] = useState({});

  // Context
  const { parameters, setParameters } = useContext(ConfigContext);

  // onChange functions
  const onChangeHandler = (key, value) => {
    // Update the input
    if (Array.isArray(inputs[key])) {
      const changes = { ...inputs, [key]: value.split("\n") };
      setInputs(changes);
      setParameters(changes);
    } else {
      const changes = { ...inputs, [key]: value.label ? value.label : value };
      setInputs(changes);
      setParameters(changes);
    }
  };

  // Helper functions
  const resetAllInputs = () => {
    try {
      setInputs(
        Object.assign(
          ...Object.keys(inputs).map((key) => ({
            [key]: Array.isArray(inputs[key]) ? [] : "",
          }))
        )
      );
    } catch (e) {
      setInputs({});
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (!isEqual(parameters, {})) {
        setInputs(parameters);
      } else {
        setInputs(
          Object.assign(
            ...module.parameters.map((param) => ({
              [param.key]: param.dataType === "string" ? "" : [],
            }))
          )
        );
      }
    } catch (e) {
      resetAllInputs({});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  useEffect(() => {
    if (isEqual(parameters, {}) && !isEqual(inputs, {})) {
      resetAllInputs();
    } else if (!isEqual(parameters, {})) {
      setInputs(parameters);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  // JSX
  return !module || !module.parameters || !Array.isArray(module.parameters) ? (
    <></>
  ) : (
    module.parameters.map((param, index) => (
      <div key={index}>
        <FormGroup>
          <Label>
            {param?.label ? param?.label : param?.key}{" "}
            {param?.isRequired && "*"}
          </Label>
          {param?.allowedValues?.length === 0 ? (
            <CustomInput
              type={param.dataType === "array" ? "textarea" : "text"}
              rows={param.dataType === "array" ? 3 : 1}
              placeholder={param.key}
              value={
                inputs[param.key] && param.dataType === "array"
                  ? inputs[param.key].join("\n")
                  : inputs[param.key]
              }
              onChange={(e) => onChangeHandler(param.key, e.target.value)}
            />
          ) : (
            <Select
              closeMenuOnSelect={true}
              isSearchable
              value={
                inputs[param.key] && inputs[param.key]
                  ? { value: inputs[param.key], label: inputs[param.key] }
                  : ""
              }
              onChange={(e) => onChangeHandler(param.key, e)}
              options={param.allowedValues.map((allowedValue) => ({
                value: allowedValue,
                label: allowedValue,
              }))}
              className="custom-input"
            />
          )}
        </FormGroup>
      </div>
    ))
  );
};

export default BlockModuleInputs;
