/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////////GROUPS DEFAULT STATE
const groupsDefaultState = [];
/////////////////////////////////////////////////////////////////////////////GROUPS REDUCER
const groups = (state = groupsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_GROUPS_SUCCESS:
      return [...action.groups];
    case types.GET_GROUPS_FAILURE:
      return [...state];

    // POST request
    case types.POST_GROUP_SUCCESS:
      return [...state, action.group];
    case types.POST_GROUP_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_GROUP_SUCCESS:
      return state.map(group => {
        if (group._id === action.group._id) {
          return { ...group, ...action.group };
        } else {
          return group;
        }
      });
    case types.PUT_GROUP_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_GROUP_SUCCESS:
      return state.filter(group => group._id !== action.group._id);
    case types.DELETE_GROUP_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default groups;
