///////////////////////////////////////////////////////////////////////////////////MODULES
import { BrowserRouter, Route, Routes } from 'react-router-dom';
/////////////////////////////////////////////////////////////////////////////////////PAGES
import AuthPage from '../components/pages/AuthPage';
import DashboardPage from '../components/pages/DashboardPage';
import ConfigsPage from '../components/pages/ConfigsPage';
import SitesPage from '../components/pages/SitesPage';
import GroupsPage from '../components/pages/GroupsPage';
import NodesPage from '../components/pages/NodesPage';
import ModulesPage from '../components/pages/ModulesPage';
import AdminPage from '../components/pages/AdminPage';
import ConfigsForm from '../components/configs/ConfigsForm';
import SitesForm from '../components/sites/SitesForm';
import GroupsForm from '../components/groups/GroupsForm';
import NodesForm from '../components/nodes/NodesForm';
import ModulesForm from '../components/modules/ModulesForm';
import CommunityPage from '../components/pages/CommunityPage';
import HistoryPage from '../components/pages/HistoryPage';
import ResultsPage from '../components/pages/ResultsPage';
import PrivacyPolicyPage from '../components/pages/legal/PrivacyPolicyPage';
import CookiesPolicyPage from '../components/pages/legal/CookiesPolicyPage';
import TermsConditions from '../components/pages/legal/TermsConditions';
import DisclaimerPage from '../components/pages/legal/DisclaimerPage';
import ReturnPolicyPage from '../components/pages/legal/ReturnPolicyPage';
import FailedPaymentPage from '../components/pages/payment/FailedPaymentPage';
import SuccessPaymentPage from '../components/pages/payment/SuccessPaymentPage';
import NotFoundPage from '../components/pages/NotFoundPage';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Header from '../components/layout/Header';
import Alert from '../components/layout/Alert';
import Footer from '../components/layout/Footer';
import ScrollUp from '../components/layout/ScrollUp';
//////////////////////////////////////////////////////////////////////////////////////////

const AppRouter = () => (
  <BrowserRouter>
    <>
      <ScrollUp />
      <Header />
      <Alert />
      <Routes>
        {/* Pages */}
        <Route exact path='/auth' element={<AuthPage />} />
        <Route exact path='/' element={<DashboardPage />} />
        <Route exact path='/configs' element={<ConfigsPage />} />
        <Route exact path='/configs/edit' element={<ConfigsForm />} />
        <Route exact path='/configs/edit/:id' element={<ConfigsForm />} />
        <Route exact path='/modules' element={<ModulesPage />} />
        <Route exact path='/modules/edit' element={<ModulesForm />} />
        <Route exact path='/modules/edit/:id' element={<ModulesForm />} />
        <Route exact path='/sites' element={<SitesPage />} />
        <Route exact path='/sites/edit' element={<SitesForm />} />
        <Route exact path='/sites/edit/:id' element={<SitesForm />} />
        <Route exact path='/groups' element={<GroupsPage />} />
        <Route exact path='/groups/edit' element={<GroupsForm />} />
        <Route exact path='/groups/edit/:id' element={<GroupsForm />} />
        <Route exact path='/nodes' element={<NodesPage />} />
        <Route exact path='/nodes/edit' element={<NodesForm />} />
        <Route exact path='/nodes/edit/:id' element={<NodesForm />} />
        <Route exact path='/community' element={<CommunityPage />} />
        <Route exact path='/history' element={<HistoryPage />} />
        <Route exact path='/results/:id' element={<ResultsPage />} />
        <Route exact path='/admin' element={<AdminPage />} />
        {/* Payment Pages */}
        <Route exact path='/success-payment' element={<SuccessPaymentPage />} />
        <Route exact path='/failed-payment' element={<FailedPaymentPage />} />
        {/* Legal Pages */}
        <Route exact path='/privacy-policy' element={<PrivacyPolicyPage />} />
        <Route exact path='/cookie-policy' element={<CookiesPolicyPage />} />
        <Route exact path='/terms-conditions' element={<TermsConditions />} />
        <Route exact path='/disclaimer' element={<DisclaimerPage />} />
        <Route exact path='/return-policy' element={<ReturnPolicyPage />} />
        {/* 404 */}
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
      <Footer />
    </>
  </BrowserRouter>
);

export default AppRouter;
