///////////////////////////////////////////////////////////////////////////////////MODULES
import io from 'socket.io-client';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from './resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

const socket = io(resolveHost());
socket.emit('connected', localStorage.getItem('nodeconfig_ls_clientId'));

export const sendMessage = ({ destination, payload }) => {
  socket.emit('sendEvent', {
    source: localStorage.getItem('nodeconfig_ls_clientId'),
    destination,
    payload,
  });
};

export default socket;
