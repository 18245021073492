///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse, Alert } from '@ibiliaze/reactstrap';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ObjectListItemButtons from '../buttons/ObjectListItemButtons';
import PingButton from '../buttons/PingButton';
//////////////////////////////////////////////////////////////////////////////////////////

const NodesListItem = ({ auth, node, deleteNode, setAlert }) => {
  // State
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState('');

  // Toggle functions
  const toggle = _ => setIsOpen(!isOpen);

  // History
  const history = useNavigate();

  // onClick functions
  const onEditClick = _ => history(`/nodes/edit/${node._id}`);

  // JSX
  return (
    <div key={node._id} className='list-item'>
      <h6 onClick={onEditClick} className='text-button'>
        {node.nodeName}
      </h6>
      <div className='list-item__p1'>{node._id}</div>
      <br />
      {message && (
        <>
          <Alert color='info'>{message}</Alert>
          <br />
        </>
      )}
      <div className='list-item__p1'>Host: {node?.host}</div>
      <div className='list-item__p1'>Platform: {node?.platform}</div>
      <div onClick={toggle} className='text-button'>
        expand
      </div>
      <Collapse isOpen={isOpen}>
        <hr />
        <PingButton auth={auth} node={node} setAlert={setAlert} setMessage={setMessage} />
        <ObjectListItemButtons auth={auth} object={node} objectType='node' deleteObject={deleteNode} />
      </Collapse>
    </div>
  );
};

export default NodesListItem;
