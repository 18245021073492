///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from "../utils/httpRequest";
//////////////////////////////////////////////////////////////////////////////////////////

export const useGetObjects = (inputs, page, search, setLoadSpinner) => {
  const [objects, setObjects] = useState([]);

  useEffect(() => {
    const req = async () => {
      setLoadSpinner(true);

      try {
        const response = await apiRequest({
          endpoint: "community",
          method: "GET",
          params: `?limit=10&skip=${10 * (page - 1)}&objectName=${
            inputs.objectName
          }&creator=${inputs.creator}${
            inputs.objectType === "all"
              ? ""
              : `&objectType=${inputs.objectType}`
          }`,
          data: null,
        });
        if (response.isError) throw new Error(response.message);
        if (!Array.isArray(response))
          throw new Error("Failed to fetch history");

        setObjects(response);
        setLoadSpinner(false);
      } catch (e) {
        setObjects([]);
        setLoadSpinner(false);
      }
    };

    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.objectType, search, page]);

  return [objects, setObjects];
};
