///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Spinner,
} from "@ibiliaze/reactstrap";
import errorParser from "@ibiliaze/http-error-parser";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from "../../utils/httpRequest";
//////////////////////////////////////////////////////////////////////////////////////////

const SubscriptionPortal = ({ setAlert, onProductClick = () => {} }) => {
  // State
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState({});

  // Lifecycle hooks
  useEffect(() => {
    const req = async () => {
      try {
        setLoading(true);

        const res = await apiRequest({ endpoint: "product", method: "GET" });

        if (res.isError) throw new Error(res.message);
        if ("currency" in res?.products && "services" in res?.products) {
          setProducts(res?.products);
        } else {
          throw new Error("Services could not be found");
        }
        setLoading(false);
      } catch (e) {
        setLoading(false);
        setAlert(errorParser(e), true, null, null);
        setProducts({});
      }
    };

    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // JSX
  return (
    <>
      <h4 className="t-align-c">
        {loading && (
          <>
            Loading... <Spinner color="primary" />
            <br />
          </>
        )}
      </h4>
      <Row>
        {products?.services?.map((service, i) => (
          <Col key={i} md={3}>
            <Card
              className={`custom-card ${
                service.bestpick ? "custom-card-special" : ""
              }`}
              onClick={(_) => onProductClick(service)}
            >
              <CardBody className="custom-card-body">
                <CardTitle tag="h5">
                  {service.serviceName}{" "}
                  {service.bestpick ? (
                    <span className="text-gradient"> (Best Pick)</span>
                  ) : (
                    ""
                  )}
                </CardTitle>
                <CardText>
                  <s>{products?.currency + service.oldPrice / 100}</s>{" "}
                  {products?.currency + service.price / 100} per month
                </CardText>
                {service.objectCount.map((object, i) => (
                  <CardText key={i}>
                    {object.allowedCount} {object.objectType}s
                  </CardText>
                ))}
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
      <br />
    </>
  );
};

export default SubscriptionPortal;
