///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormGroup, Table, Row, Col, Label, CustomInput } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const ModuleListItem = ({ module }) => (
  <>
    <Row>
      <Col md={4}>
        <FormGroup>
          <Label>Module Name</Label>
          <CustomInput
            disabled
            type='text'
            placeholder='Module Name'
            value={module && module.moduleName}
            onChange={e => {}}
          />
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label>Type</Label>
          <CustomInput disabled type='text' placeholder='Type' value={module && module.type} onChange={e => {}} />
        </FormGroup>
      </Col>
      <Col md={4}>
        <FormGroup>
          <Label>Creator</Label>
          <CustomInput disabled type='text' placeholder='Creator' value={module && module.creator} onChange={e => {}} />
        </FormGroup>
      </Col>
      <Col md={12}>
        <FormGroup>
          <Label>Description</Label>
          <CustomInput
            disabled
            type='textarea'
            rows={module?.description?.split('\n')?.length || 1}
            placeholder='Description'
            value={module && module.description}
            onChange={e => {}}
          />
        </FormGroup>
      </Col>
    </Row>
    <hr />
    <Table>
      <thead>
        <th>Key</th>
        <th>Data-Type</th>
        <th>Required</th>
        <th>Default</th>
      </thead>
      <tbody className='parameters-table-body'>
        {module &&
          module.parameters?.map((param, index) => (
            <tr key={index}>
              <td>{param.key}</td>
              <td>{param.dataType}</td>
              <td>{String(param.isRequired)}</td>
              <td>{param.default}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  </>
);

export default ModuleListItem;
