///////////////////////////////////////////////////////////////////////////////////MODULES
import { memo, useState } from 'react';
import { CustomButton, Alert, UncontrolledTooltip, AccordionHeader, AccordionBody } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from '../../utils/icons';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { copyToClipboard } from '../../utils/copyToClipboard';
import { download } from '../../utils/httpRequest';
//////////////////////////////////////////////////////////////////////////////////////////

const Node = ({ node, itemId, onNodeClick }) => {
  // State
  const [clientMessage, setClientMessage] = useState({ text: '', loading: false, color: 'secondary' });

  // onClick functions
  const onCopyClick = _ => {
    copyToClipboard(node?.result, null, 2);
    setClientMessage(_ => ({ text: 'Copied to clipboard', loading: false, color: 'success' }));
  };
  const onDownloadClick = _ => download(`${node?.nodeName}-${itemId}`, node?.result);

  // JSX
  return (
    <>
      <AccordionHeader targetId={node?._id}>
        {node?.isError && (
          <>
            <FiIcons.FiAlertCircle /> <span className='list-item__p2'>error - </span>
          </>
        )}{' '}
        {node?.nodeName}
      </AccordionHeader>
      <AccordionBody accordionId={node?._id}>
        {clientMessage.text && <Alert color={clientMessage.color}>{clientMessage.text}</Alert>}
        <pre className='list-item__pre'>
          <h4 onClick={_ => onNodeClick(node?._id)} className='text-button t-align-c'>
            {node?.nodeName}
          </h4>
          <br />
          {node?.result}
          <hr />
          <CustomButton outline onClick={onDownloadClick} color='success' id={`download-${node?._id}-${itemId}`}>
            <FiIcons.FiDownload />
          </CustomButton>
          <UncontrolledTooltip placement='bottom' target={`download-${node?._id}-${itemId}`}>
            Download
          </UncontrolledTooltip>
          <CustomButton outline onClick={onCopyClick} color='primary' id={`copy-${node?._id}-${itemId}`}>
            <FiIcons.FiCopy />
          </CustomButton>
          <UncontrolledTooltip placement='bottom' target={`copy-${node?._id}-${itemId}`}>
            Copy
          </UncontrolledTooltip>
        </pre>
      </AccordionBody>
    </>
  );
};

export default memo(Node);
