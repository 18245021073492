///////////////////////////////////////////////////////////////////////////////////MODULES
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
////////////////////////////////////////////////////////////////////////////////APP ROUTER
import AppRouter from './routers/AppRouter';
/////////////////////////////////////////////////////////////////////////////////////STORE
import configureStore from './store/configureStore';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getOrg } from './actions/auth';
import { getSettings } from './actions/admin';
import { handleResponse } from './actions/socket';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import socket from './utils/socket';
///////////////////////////////////////////////////////////////////////////////////////CSS
import 'normalize.css/normalize.css';
import './styles/styles.scss';
////////////////////////////////////////////////////////////////////////////STORE TO REACT

document.getElementsByName('viewport')[0].content = 'width=device-width, initial-scale=1, maximum-scale=1';

const store = configureStore();
const App = () => {
  useEffect(() => {
    socket.off('messageReceived');
    socket.removeAllListeners('messageReceived');
    socket.on('messageReceived', data => store.dispatch(handleResponse(data)));
    const request = async () => {
      try {
        await store.dispatch(getOrg());
        await store.dispatch(getSettings());
      } catch (e) {
        console.log(e);
      }
    };

    request();
  }, []);

  return (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
};
//////////////////////////////////////////////////////////////////////////////////////////
export default App;
