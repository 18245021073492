///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { CustomButton, Form, FormGroup, Label, CustomInput } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import superTrim from '../../utils/superTrim';
//////////////////////////////////////////////////////////////////////////////////////////

const OrgForm = ({ auth, putOrg, toggleModal, toggleCollapse }) => {
  // State
  const [inputs, setInputs] = useState({ orgName: '', domain: '', username: '' });

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: superTrim(e.target.value) }));

  // Lifecycle hooks
  useEffect(() => {
    // Used when editing the account
    if (auth && auth.isAuthenticated) {
      setInputs({
        orgName: auth.org.orgName,
        domain: auth.org.domain,
        username: localStorage.getItem('nodeconfig_ls_username'),
      });
    }
  }, [auth]);

  // onSubmit function
  const onSubmit = async e => {
    e.preventDefault();

    // Attempt to update the account
    await putOrg({ domain: inputs.domain });

    // Update the local username
    localStorage.setItem('nodeconfig_ls_username', inputs.username);

    toggleModal();
    toggleCollapse();
  };

  // JSX
  return (
    <Form onSubmit={onSubmit}>
      <hr />
      <FormGroup>
        <Label>Organisation Name *</Label>
        <CustomInput
          disabled
          type='text'
          placeholder='Organisation Name'
          value={inputs.orgName}
          name='orgName'
          onChange={onInputsChange}
        />
      </FormGroup>

      <FormGroup>
        <Label>Domain *</Label>
        <CustomInput
          required
          type='text'
          placeholder='Domain'
          value={inputs.domain}
          name='domain'
          onChange={onInputsChange}
        />
      </FormGroup>

      <FormGroup>
        <Label>Username *</Label>
        <CustomInput
          required
          type='text'
          placeholder='Username'
          value={inputs.username}
          name='username'
          onChange={onInputsChange}
        />
      </FormGroup>

      <CustomButton color='primary'>Submit</CustomButton>
    </Form>
  );
};

export default OrgForm;
