export const allowedPlatforms = [
  // Cisco
  'Cisco IOS',
  'Cisco ASA',
  'Cisco IOS-XE',
  'Cisco IOS-XR',
  'Cisco SAOS',
  'Cisco FTD',
  'Cisco s300',
  'Cisco TP',
  'Cisco WLC',
  // Linux
  'Linux',
  // Juniper
  'Juniper',
  'Juniper Junos',
  'Juniper Screeno',
  // Palo-Alto
  'Palo-Alto PAN-OS',
  // Arista
  'Arista EOS',
  // VyOS
  'VyOS',
  // Ruckus
  'Ruckus FastIron',
  // HP
  'HP Comware',
  'HP ProCurve',
  // Aruba
  'Aruba OS',
  'Aruba OSSwitch',
  'Aruba ProCurve',
  // F5
  'F5 Linux',
  'F5 LTM',
  'F5 TMSH',
  // Exteme Networks
  'Extreme',
  'Extreme ERS',
  'ExtremeXOS',
  'Extreme NetIron',
  'Extreme Network OS',
  'Extreme SLX',
  'Extreme VDX',
  'Extreme VSP',
  'Extreme WiNG',
  // Checkpoint
  'CheckPoint GAiA',
];
