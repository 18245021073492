///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/module
export const getModulesSuccess = modules => ({ type: types.GET_MODULES_SUCCESS, modules });
export const getModulesFailure = errorMessage => ({ type: types.GET_MODULES_FAILURE, errorMessage });
export const getModules = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/module` : `${resolveHost()}/api/module${endpoint}`);

    dispatch(getModulesSuccess(res.data));
  } catch (e) {
    dispatch(getModulesFailure(e.message));
  }
};

// POST /api/module
export const postModuleSuccess = module => ({ type: types.POST_MODULE_SUCCESS, module });
export const postModuleFailure = errorMessage => ({ type: types.POST_MODULE_FAILURE, errorMessage });
export const postModule = data => async dispatch => {
  try {
    dispatch(setAlert('Creating the module...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/module`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_MODULE_SUCCESS));
    dispatch(postModuleSuccess(res.data.module));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.POST_MODULE_FAILURE));
    dispatch(postModuleFailure(e.message));
  }
};

// PUT /api/module
export const putModuleSuccess = module => ({ type: types.PUT_MODULE_SUCCESS, module });
export const putModuleFailure = errorMessage => ({ type: types.PUT_MODULE_FAILURE, errorMessage });
export const putModule = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the module...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/module/${data.moduleId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_MODULE_SUCCESS));
    dispatch(putModuleSuccess(res.data.module));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_MODULE_FAILURE));
    dispatch(putModuleFailure(e.message));
  }
};

// DELETE /api/module
export const deleteModuleSuccess = module => ({ type: types.DELETE_MODULE_SUCCESS, module });
export const deleteModuleFailure = errorMessage => ({ type: types.DELETE_MODULE_FAILURE, errorMessage });
export const deleteModule = moduleId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the module...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/module/${moduleId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_MODULE_SUCCESS));
    dispatch(deleteModuleSuccess(res.data.module));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.DELETE_MODULE_FAILURE));
    dispatch(deleteModuleFailure(e.message));
  }
};
