/////////////////////////////////////////////////////////////////////////////////////TYPES
import { SOCKET_SUCCESS, SOCKET_FAILURE } from './types/types';
import errorParser from '@ibiliaze/http-error-parser';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
//////////////////////////////////////////////////////////////////////////////////////////

// Handle task res (socket.io)
export const handleResponse = data => async dispatch => {
  try {
    if (!data || !data.payload) throw new Error('Socket error');
    if (!!data.payload.isError) throw new Error(data.payload.message);

    dispatch(setAlert(data.payload.message, null, SOCKET_SUCCESS));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, null, SOCKET_FAILURE));
  }
};
