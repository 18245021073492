///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton, Collapse, Alert, UncontrolledTooltip, Spinner } from '@ibiliaze/reactstrap';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from '../../utils/icons';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ObjectListItemButtons from '../buttons/ObjectListItemButtons';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../../utils/resolveEnv';
import { sendMessage } from '../../utils/socket';
import { api } from '../../utils/httpRequest';
//////////////////////////////////////////////////////////////////////////////////////////

const ConfigsListItem = ({ auth, config, deleteConfig }) => {
  // State
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  // Toggle functions
  const toggle = () => setIsOpen(!isOpen);

  // History
  const history = useNavigate();

  // onClick functions
  const onEditClick = _ => history(`/configs/edit/${config._id}`);
  const onTemplateClick = async _ => {
    try {
      if (!auth.isAuthenticated) return history('/auth');

      setMessage('Validating the config...');
      setLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      const res = await api.post(`${resolveHost()}/api/config/validate`, config);
      sendMessage({
        destination: config.agentId,
        payload: {
          object: res.data,
          taskDetails: {
            itemName: res.data.configName,
            task: 'build',
            objectName: res.data.configName,
            objectType: 'config',
          },
        },
      });
      setMessage(`${config.configName} has been validated for a build and is sent to the NodeAgent`);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setMessage(errorParser(e));
    }
  };

  const onRunClick = async _ => {
    try {
      if (!auth.isAuthenticated) return history('/auth');

      setMessage('Validating the config...');
      setLoading(true);
      await new Promise(resolve => setTimeout(resolve, 1000));
      const res = await api.post(`${resolveHost()}/api/config/validate`, config);
      sendMessage({
        destination: config.agentId,
        payload: {
          object: res.data,
          taskDetails: {
            itemName: res.data.configName,
            task: 'config',
            objectName: res.data.configName,
            objectType: 'config',
          },
        },
      });
      setMessage(`${config.configName} has been validated for a run and is sent to the NodeAgent`);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setMessage(errorParser(e));
    }
  };

  // JSX
  return (
    <div key={config._id} className='list-item'>
      <h6 onClick={onEditClick} className='text-button'>
        {config.configName}
      </h6>
      <div className='list-item__p1'>{config._id}</div>
      <br />
      {message && (
        <>
          <Alert color='info'>
            {message} {loading && <Spinner type='grow' color='primary' size='sm' />}
          </Alert>
          <br />
        </>
      )}
      <div className='list-item__p1'>Module: {config?.moduleName}</div>
      <div className='list-item__p1'>Version: {config?.version}</div>
      <div onClick={toggle} className='text-button'>
        expand
      </div>
      <Collapse isOpen={isOpen}>
        <hr />
        <CustomButton onClick={onRunClick} color='primary' id={`run-${config._id}`} outline>
          <FiIcons.FiPlay />
        </CustomButton>
        <UncontrolledTooltip placement='bottom' target={`run-${config._id}`}>
          Run
        </UncontrolledTooltip>

        <CustomButton onClick={onTemplateClick} color='info' id={`build-${config._id}`} outline>
          <FiIcons.FiCodesandbox />
        </CustomButton>
        <UncontrolledTooltip placement='bottom' target={`build-${config._id}`}>
          Build
        </UncontrolledTooltip>

        <ObjectListItemButtons auth={auth} object={config} objectType='config' deleteObject={deleteConfig} />
      </Collapse>
    </div>
  );
};

export default ConfigsListItem;
