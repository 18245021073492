////////////////////////////////////////////////////////////////////////////////SITE TYPES
export const GET_SITES_SUCCESS = 'GET_SITES_SUCCESS';
export const GET_SITES_FAILURE = 'GET_SITES_FAILURE';
export const POST_SITE_SUCCESS = 'POST_SITE_SUCCESS';
export const POST_SITE_FAILURE = 'POST_SITE_FAILURE';
export const PUT_SITE_SUCCESS = 'PUT_SITE_SUCCESS';
export const PUT_SITE_FAILURE = 'PUT_SITE_FAILURE';
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS';
export const DELETE_SITE_FAILURE = 'DELETE_SITE_FAILURE';
////////////////////////////////////////////////////////////////////////////////NODE TYPES
export const GET_NODES_SUCCESS = 'GET_NODES_SUCCESS';
export const GET_NODES_FAILURE = 'GET_NODES_FAILURE';
export const POST_NODE_SUCCESS = 'POST_NODE_SUCCESS';
export const POST_NODE_FAILURE = 'POST_NODE_FAILURE';
export const PUT_NODE_SUCCESS = 'PUT_NODE_SUCCESS';
export const PUT_NODE_FAILURE = 'PUT_NODE_FAILURE';
export const DELETE_NODE_SUCCESS = 'DELETE_NODE_SUCCESS';
export const DELETE_NODE_FAILURE = 'DELETE_NODE_FAILURE';
///////////////////////////////////////////////////////////////////////////////GROUP TYPES
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILURE = 'GET_GROUPS_FAILURE';
export const POST_GROUP_SUCCESS = 'POST_GROUP_SUCCESS';
export const POST_GROUP_FAILURE = 'POST_GROUP_FAILURE';
export const PUT_GROUP_SUCCESS = 'PUT_GROUP_SUCCESS';
export const PUT_GROUP_FAILURE = 'PUT_GROUP_FAILURE';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILURE = 'DELETE_GROUP_FAILURE';
//////////////////////////////////////////////////////////////////////////////CONFIG TYPES
export const GET_CONFIGS_SUCCESS = 'GET_CONFIGS_SUCCESS';
export const GET_CONFIGS_FAILURE = 'GET_CONFIGS_FAILURE';
export const POST_CONFIG_SUCCESS = 'POST_CONFIG_SUCCESS';
export const POST_CONFIG_FAILURE = 'POST_CONFIG_FAILURE';
export const PUT_CONFIG_SUCCESS = 'PUT_CONFIG_SUCCESS';
export const PUT_CONFIG_FAILURE = 'PUT_CONFIG_FAILURE';
export const DELETE_CONFIG_SUCCESS = 'DELETE_CONFIG_SUCCESS';
export const DELETE_CONFIG_FAILURE = 'DELETE_CONFIG_FAILURE';
//////////////////////////////////////////////////////////////////////////////MODULE TYPES
export const GET_MODULES_SUCCESS = 'GET_MODULES_SUCCESS';
export const GET_MODULES_FAILURE = 'GET_MODULES_FAILURE';
export const POST_MODULE_SUCCESS = 'POST_MODULE_SUCCESS';
export const POST_MODULE_FAILURE = 'POST_MODULE_FAILURE';
export const PUT_MODULE_SUCCESS = 'PUT_MODULE_SUCCESS';
export const PUT_MODULE_FAILURE = 'PUT_MODULE_FAILURE';
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS';
export const DELETE_MODULE_FAILURE = 'DELETE_MODULE_FAILURE';
////////////////////////////////////////////////////////////////////////////////AUTH TYPES
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const GET_ORG_SUCCESS = 'GET_ORG_SUCCESS';
export const GET_ORG_FAILURE = 'GET_ORG_FAILURE';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';
export const PUT_ORG_SUCCESS = 'PUT_ORG_SUCCESS';
export const PUT_ORG_FAILURE = 'PUT_ORG_FAILURE';
export const DELETE_ORG_SUCCESS = 'DELETE_ORG_SUCCESS';
export const DELETE_ORG_FAILURE = 'DELETE_ORG_FAILURE';
///////////////////////////////////////////////////////////////////////////////ALERT TYPES
export const GET_MESSAGES = 'GET_MESSAGES';
export const CLEAR_MESSAGES = 'CLEAR_MESSAGES';
////////////////////////////////////////////////////////////////////////////SETTINGS TYPES
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';
export const PUT_SETTINGS_SUCCESS = 'PUT_SETTINGS_SUCCESS';
export const PUT_SETTINGS_FAILURE = 'PUT_SETTINGS_FAILURE';
///////////////////////////////////////////////////////////////////////////////EMAIL TYPES
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS';
export const CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE';
//////////////////////////////////////////////////////////////////////////////SOCKET TYPES
export const SOCKET_SUCCESS = 'SOCKET_SUCCESS';
export const SOCKET_FAILURE = 'SOCKET_FAILURE';
//////////////////////////////////////////////////////////////////////////////////////////
