///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Collapse,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Table,
  CustomButton,
  CustomNav,
} from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putOrg, deleteOrg, logout, logoutAll } from '../../actions/auth';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import scroll from '../../utils/scroll';
import constants from '../../utils/constants';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import OrgForm from './OrgForm';
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ auth, putOrg, deleteOrg, logout, logoutAll }) => {
  // State
  const [isCollapseOpen, setIsCollapseOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [nestedModal, setNestedModal] = useState(false);

  // Toggle functions
  const toggleCollapse = _ => setIsCollapseOpen(!isCollapseOpen);
  const toggleModal = _ => setModal(!modal);
  const toggleNestedModal = _ => setNestedModal(!nestedModal);

  // History
  const navigate = useNavigate();

  // onClick functions
  const onEditClick = _ => toggleCollapse();
  const onDeleteClick = _ => toggleNestedModal();
  const onContinueDeleteClick = async _ => {
    toggleNestedModal();
    await deleteOrg();
  };

  // JSX
  return (
    <>
      <CustomNav
        position='fixed'
        dark={true}
        logoSrc={constants.mainPicLogo}
        logoStyle={{ height: '40px' }}
        navbarBrand='nodeconfig'
        navbarBrandHref='#'
        navbarBrandOnClick={_ => navigate('/')}
        backgroundColor='rgba(21, 33, 48, 0.85)'
        navlinks={[
          {
            title: 'Admin',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/admin');
            },
            hide: !auth?.isAuthenticated || !auth?.org?.admin,
          },
          {
            title: 'Sites',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/sites');
            },
            hide: !auth?.isAuthenticated,
          },
          {
            title: 'Groups',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/groups');
            },
            hide: !auth?.isAuthenticated,
          },
          {
            title: 'Nodes',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/nodes');
            },
            hide: !auth?.isAuthenticated,
          },
          {
            title: 'Modules',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/modules');
            },
            hide: !auth?.isAuthenticated,
          },
          {
            title: 'Configs',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/configs');
            },
            hide: !auth?.isAuthenticated,
          },
          {
            title: 'History',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/history');
            },
            hide: !auth?.isAuthenticated,
          },
          {
            title: 'About',
            href: '#',
            onClick: _ => {
              navigate('/');
              scroll(document.getElementById('about-section')?.offsetTop);
            },
            hide: auth?.isAuthenticated,
          },
          {
            title: 'Pricing',
            href: '#',
            onClick: _ => {
              navigate('/');
              scroll(document.getElementById('pricing-section')?.offsetTop);
            },
            hide: auth?.isAuthenticated,
          },
          {
            title: 'Community',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/community');
            },
            hide: false,
          },
          {
            title: 'Guide',
            href: '#',
            onClick: _ => {
              navigate('/');
              scroll(document.getElementById('guide-section')?.offsetTop);
            },
            hide: false,
          },
          {
            title: 'Contact',
            href: '#',
            onClick: _ => window.scrollTo(0, document.body.scrollHeight),
            hide: auth?.isAuthenticated,
          },
        ]}
        navlinksRight={[
          // auth links
          {
            title: auth && auth?.org ? `${localStorage.getItem('nodeconfig_ls_username')}@${auth?.org?.orgName}` : '',
            href: '#',
            onClick: _ => toggleModal(),
            hide: !auth?.isAuthenticated,
          },
          {
            title: 'Logout',
            href: '#',
            onClick: async _ => await logout(),
            hide: !auth?.isAuthenticated,
          },
          // guest links
          {
            title: 'Login',
            href: '#',
            onClick: _ => {
              window.scrollTo(0, 0);
              navigate('/auth');
            },
            hide: auth?.isAuthenticated,
          },
        ]}
      />

      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Account Settings</ModalHeader>
        <ModalBody>
          <Table>
            <tbody>
              <tr>
                <th scope='row'>Account ID</th>
                <td>{auth && auth?.org ? auth?.org?._id : null}</td>
              </tr>
              <tr>
                <th scope='row'>Email</th>
                <td>{auth && auth?.org ? auth?.org?.email : null}</td>
              </tr>
              <tr>
                <th scope='row'>Organization Name</th>
                <td>{auth && auth?.org ? auth?.org?.orgName : null}</td>
              </tr>
              <tr>
                <th scope='row'>Domain</th>
                <td>{auth && auth?.org ? auth?.org?.domain : null}</td>
              </tr>
              <tr>
                <th scope='row'>Logged in as</th>
                <td>{auth && auth?.org ? localStorage.getItem('nodeconfig_ls_username') : null}</td>
              </tr>
              <tr>
                <th scope='row'>Created</th>
                <td>{auth && auth?.org ? auth?.org?.createdAt : null}</td>
              </tr>
              <tr>
                <th scope='row'>Last updated</th>
                <td>{auth && auth?.org ? auth?.org?.updatedAt : null}</td>
              </tr>
              <tr>
                <th scope='row'>Plan</th>
                <td>{auth && auth?.org ? auth?.org?.subscriptionType : null}</td>
              </tr>
            </tbody>
          </Table>

          <ModalFooter>
            <CustomButton onClick={onEditClick} color='primary'>
              Edit account
            </CustomButton>
            <CustomButton onClick={async _ => await logoutAll()} color='danger'>
              Sign out from all sessions
            </CustomButton>
            <CustomButton onClick={onDeleteClick} color='danger'>
              Delete account
            </CustomButton>
          </ModalFooter>

          <Collapse isOpen={isCollapseOpen} className='object-list__item__secondary-collapse'>
            <OrgForm auth={auth} putOrg={putOrg} toggleModal={toggleModal} toggleCollapse={toggleCollapse} />
          </Collapse>

          <Modal isOpen={nestedModal} toggle={toggleNestedModal}>
            <ModalHeader>Warning!</ModalHeader>
            <ModalBody>
              This action will delete the following objects associated with this account:
              <ul>
                <li>Configs</li>
                <li>Modules</li>
                <li>Sites</li>
                <li>Groups</li>
                <li>Nodes</li>
              </ul>
              <hr />
              <br />
              This action is not reversible.
              <br />
              <br />
              Are you sure you want to continue?
            </ModalBody>
            <ModalFooter>
              <CustomButton onClick={onContinueDeleteClick} color='danger'>
                Continue
              </CustomButton>
              <CustomButton onClick={toggleNestedModal}>Cancel</CustomButton>
            </ModalFooter>
          </Modal>
        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = state => ({ auth: state.auth });

export default connect(mapStateToProps, { putOrg, deleteOrg, logout, logoutAll })(Header);
