export const copyToClipboard = text => {
  try {
    navigator.clipboard
      .writeText(text)
      .then(() => {})
      .catch(e => copyToClipboardBackup(text));
  } catch (e) {
    copyToClipboardBackup(text);
  }
};

export const copyToClipboardBackup = text => {
  try {
    const el = document.createElement('textarea');
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  } catch (e) {
    console.log(e);
  }
};
