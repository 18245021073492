export const env = process.env;

const constants = {
  cust: 'NodeConfig',
  custSm: 'nodeconfig',
  custJs: 'nodeconfig',
  slogan: 'Automate your entire infrastructure',
  phone1: '+447852878811',
  email1: 'nodeconfig1@gmail.com',
  hqAddr: '',
  chatPerson: 'Ibi',
  mainPic: '/img/ncm.gif',
  mainPicLogo: '/img/logo-no-bg-no-word.png',
  instagram: 'nodeconfig',
  facebook: 'nodeconfig',
  twitter: 'nodeconfig',
  github: 'ibiliaze',
  linkedin: 'company/98591986',
  youtube: 'UC8RcpGkDOp5WcDsuMQPwYWQ',
  bookingOption: 'BOOK_FIRST',
  aboutUs: [
    {
      header: '99.99% Uptime SLA',
      body: 'Your website has been built to sustain an availability of 99.99%',
    },
    {
      header: 'Admin Management',
      body: 'You can have the ability to create admin users that will manage your customer servicing needs',
    },
    {
      header: 'Earn Money with your Website',
      body: 'Any customer that chooses to personalise their website similar to yours will earn you cashback benefits!',
    },
  ],
};

export default constants;
