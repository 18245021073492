///////////////////////////////////////////////////////////////////////////////////MODULES
import { Pagination, PaginationItem, PaginationLink } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const Paging = ({ page, onPageClick }) => (
  <Pagination size='md'>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(1)} first />
    </PaginationItem>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(page - 1)} previous />
    </PaginationItem>
    <PaginationItem>
      <PaginationLink className='current-page' onClick={e => onPageClick(page)}>
        {page}
      </PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(page + 1)}>{page + 1}</PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(page + 2)}>{page + 2}</PaginationLink>
    </PaginationItem>
    <PaginationItem>
      <PaginationLink onClick={e => onPageClick(page + 1)} next />
    </PaginationItem>
  </Pagination>
);

export default Paging;
