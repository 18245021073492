///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Spinner } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import ModuleContext from '../../context/ObjectContext';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postModule, putModule } from '../../actions/modules';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ModulesFormInputs from './ModulesFormInputs';
import { ModuleHowto } from '../layout/Howto';
//////////////////////////////////////////////////////////////////////////////////////////

const ModulesForm = ({ auth, postModule, putModule }) => {
  // State
  const [moduleId, setModuleId] = useState('');
  // For inputs
  const [moduleName, setModuleName] = useState('');
  const [description, setDescription] = useState('');
  const [creator, setCreator] = useState('');
  const [iterator, setIterator] = useState('');
  const [type, setType] = useState('');
  const [template, setTemplate] = useState('');
  const [parameters, setParameters] = useState([]);

  // History
  const navigate = useNavigate();

  // onChange functions
  const ModuleNameChange = e => setModuleName(e.target.value);
  const DescriptionChange = e => setDescription(e.target.value);
  const CreatorChange = e => setCreator(e.target.value);
  const IteratorChange = e => setIterator(e.target.value);
  const TypeChange = e => setType(e.value); // from react-select
  const TemplateChange = e => setTemplate(e.target.value);

  // Lifecycle hooks
  useEffect(() => {
    const edit = window.location.pathname.replace('/modules/edit', '');
    !!edit && setModuleId(edit.replace('/', ''));

    const req = async () => {
      try {
        if (!!edit) {
          const response = await apiRequest({ endpoint: 'module', method: 'GET', params: edit });
          if (response.isError) throw new Error(response.message);

          setModuleName(response?.moduleName);
          setDescription(response?.description);
          setCreator(response?.creator);
          setIterator(response?.iterator);
          setType(response?.type);
          setTemplate(response.template);
          setParameters(response.parameters ? response.parameters : []);
        }
      } catch (e) {
        setModuleId('');
      }
    };
    req();
  }, []);

  // onSubmit function
  const onSubmit = async e => {
    e.preventDefault();

    if (!auth.isAuthenticated) return navigate.push('/auth');

    const req = moduleId ? putModule : postModule;
    await req({
      moduleId: moduleId ? moduleId : undefined,
      moduleName: moduleId ? undefined : moduleName,
      description,
      creator,
      iterator: moduleId ? undefined : iterator,
      type: moduleId ? undefined : type,
      parameters: moduleId ? undefined : parameters,
      template,
    });
  };

  // JSX
  return (
    <ModuleContext.Provider
      value={{
        moduleId,
        moduleName,
        description,
        creator,
        iterator,
        type,
        template,
        parameters,
        ModuleNameChange,
        DescriptionChange,
        CreatorChange,
        IteratorChange,
        TypeChange,
        TemplateChange,
        setModuleId,
        setParameters,
      }}
    >
      <div className='below-header'>
        <h1 className='t-align-c no-m'>Module Editor</h1>
        <section className='page p-b-m p-t-m' style={{ minHeight: '100vh' }}>
          <Form onSubmit={onSubmit}>
            {moduleId && !moduleName ? (
              <Spinner color='primary' className='object-list__spnr' />
            ) : (
              <ModulesFormInputs />
            )}
          </Form>
          <ModuleHowto />
        </section>
      </div>
    </ModuleContext.Provider>
  );
};

const mapStateToProps = state => ({ message: state.message, isLoading: state.modules.isLoading, auth: state.auth });

export default connect(mapStateToProps, { postModule, putModule })(ModulesForm);
