///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useContext, useEffect } from "react";
import { isEqual } from "lodash";
import {
  Table,
  FormGroup,
  CustomInput,
  Label,
  Row,
  Col,
  CloseButton,
  Alert,
} from "@ibiliaze/reactstrap";
import Select from "react-select";
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from "../../utils/icons";
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import ConfigContext from "../../context/ObjectContext";
//////////////////////////////////////////////////////////////////////////////////////////

const InteratedModuleInputs = ({ module }) => {
  // State
  const [setIndex, setSetIndex] = useState(null);
  const [sets, setSets] = useState([]);
  const [inputs, setInputs] = useState({});

  // Context
  const { parameters, setParameters } = useContext(ConfigContext);

  // onChange functions
  const onChangeHandler = (key, value) => {
    // Update the input
    if (Array.isArray(inputs[key])) {
      setInputs({ ...inputs, [key]: value.split("\n") });
    } else {
      setInputs({ ...inputs, [key]: value.label ? value.label : value });
    }
  };

  // Helper functions
  const resetAllInputs = (_) => {
    try {
      setInputs(
        Object.assign(
          ...Object.keys(inputs).map((key) => ({
            [key]: Array.isArray(inputs[key]) ? [] : "",
          }))
        )
      );
    } catch (e) {
      setInputs({});
    }
  };

  // onClick functions
  const onAddSetClick = (e) => {
    resetAllInputs();
    setSetIndex(null);
    setSets([...sets, inputs]);
    setParameters({ [module.iterator]: [...sets, inputs] });
  };

  const onUpdateSetClick = (e) => {
    if (setIndex || setIndex === 0) {
      /**
       * Using this condition as "0" as an number is a falsy value:
       *  - !!0 === false
       */
      sets[setIndex] = inputs;
      setSets([...sets]);
      setParameters({ [module.iterator]: [...sets] });
      resetAllInputs();
    }
    setSetIndex(null);
  };

  const onRemoveSetClick = (e, index) => {
    resetAllInputs();
    setSetIndex(null);
    /**
     * This will return the removed element.
     * The actual array will be ammended.
     */
    sets.splice(index, 1);
    setSets([...sets]);
    setParameters({ [module.iterator]: [...sets] });
  };

  const onCopySetClick = (e, index, set) => {
    setSetIndex(index);
    setInputs({ ...set });
  };

  const onClearInputsClick = (e) => resetAllInputs();

  // Lifecycle hooks
  useEffect(() => {
    try {
      setInputs(
        Object.assign(
          ...module.parameters.map((param) => ({
            [param.key]: param.dataType === "string" ? "" : [],
          }))
        )
      );
      if (!isEqual(parameters, {})) {
        setSets(parameters[module.iterator]);
      }
    } catch (e) {
      setInputs({});
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [module]);

  useEffect(() => {
    if (isEqual(parameters, {})) {
      setSets([]);
    } else {
      setSets(parameters[module.iterator]);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parameters]);

  // JSX
  return !module || !module.parameters || !Array.isArray(module.parameters) ? (
    <></>
  ) : (
    <>
      <hr />
      <h3 className="t-align-c">Module Parameters</h3>
      <br />
      <Alert color="primary">
        <div>Click on set to edit</div>
        <div>Click on header to reset inputs</div>
      </Alert>

      <Table>
        <thead className="parameters-table-header" onClick={onClearInputsClick}>
          <tr>
            <th>Set</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="parameters-table-body">
          {sets?.map((set, index) => (
            <tr
              key={index}
              onClick={(e) => onCopySetClick(e, index, set)}
              className="parameters-table-row"
            >
              <td>{set[Object.keys(set)[0]]}</td>
              <td className="t-align-r">
                <CloseButton onClick={(e) => onRemoveSetClick(e, index)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <br />

      {module.parameters.map((param, index) => (
        <div key={index}>
          <FormGroup>
            <Label>
              {param?.label ? param?.label : param?.key}{" "}
              {param?.isRequired && "*"}
            </Label>
            {param?.allowedValues?.length === 0 ? (
              <CustomInput
                type={param.dataType === "array" ? "textarea" : "text"}
                rows={param.dataType === "array" ? 3 : 1}
                placeholder={param.key}
                value={
                  inputs[param.key] && param.dataType === "array"
                    ? inputs[param.key].join("\n")
                    : inputs[param.key]
                }
                onChange={(e) => onChangeHandler(param.key, e.target.value)}
              />
            ) : (
              <Select
                closeMenuOnSelect={true}
                isSearchable
                value={
                  inputs[param.key] && inputs[param.key]
                    ? { value: inputs[param.key], label: inputs[param.key] }
                    : ""
                }
                onChange={(e) => onChangeHandler(param.key, e)}
                options={param.allowedValues.map((allowedValue) => ({
                  value: allowedValue,
                  label: allowedValue,
                }))}
                className="custom-input"
              />
            )}
          </FormGroup>
        </div>
      ))}

      <br />
      <Row>
        <Col md={setIndex || setIndex === 0 ? 6 : 12}>
          <div onClick={onAddSetClick} className="text-button t-align-c">
            <FiIcons.FiPlus /> Add Set
          </div>
        </Col>

        {(setIndex || setIndex === 0) && (
          <Col md={6}>
            <div onClick={onUpdateSetClick} className="text-button t-align-c">
              <FiIcons.FiUpload /> Update Set
            </div>
          </Col>
        )}
      </Row>
      <hr />
    </>
  );
};

export default InteratedModuleInputs;
