/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////////CONFIGS DEFAULT STATE
const configsDefaultState = [];
/////////////////////////////////////////////////////////////////////////////CONFIGS REDUCER
const configs = (state = configsDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_CONFIGS_SUCCESS:
      return [...action.configs];
    case types.GET_CONFIGS_FAILURE:
      return [...state];

    // POST request
    case types.POST_CONFIG_SUCCESS:
      return [...state, action.config];
    case types.POST_CONFIG_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_CONFIG_SUCCESS:
      return state.map(config => {
        if (config._id === action.config._id) {
          return { ...config, ...action.config };
        } else {
          return config;
        }
      });
    case types.PUT_CONFIG_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_CONFIG_SUCCESS:
      return state.filter(config => config._id !== action.config._id);
    case types.DELETE_CONFIG_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default configs;
