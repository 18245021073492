///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useContext } from 'react';
import {
  FormGroup,
  FormText,
  Label,
  Collapse,
  Row,
  Col,
  CustomButton,
  CustomInput,
  TextButton,
} from '@ibiliaze/reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from '../../utils/icons';
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import ConfigContext from '../../context/ObjectContext';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import InteratedModuleInputs from './InteratedModuleInputs';
import BlockModuleInputs from './BlockModuleInputs';
//////////////////////////////////////////////////////////////////////////////////////////

const animatedComponents = makeAnimated();

const ConfigsFormInputs = () => {
  // State
  const [isOpen, setIsOpen] = useState(true);

  // Toggle functions
  const toggle = () => setIsOpen(!isOpen);

  // Context
  const props = useContext(ConfigContext);

  // JSX
  return (
    <>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Config Name *</Label>
            <CustomInput
              autoFocus
              required
              disabled={props.configId ? true : false}
              type='text'
              placeholder='Config Name'
              value={props.configName}
              onChange={props.onConfigNameChange}
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Module *</Label>
            <Select
              isSearchable
              required
              components={animatedComponents}
              name='moduleName'
              value={props.moduleName ? { value: props.moduleName, label: props.moduleName } : ''}
              onChange={props.onModuleNameChange}
              options={props.modulesState.map(module => ({ value: module.moduleName, label: module.moduleName }))}
              className='custom-input'
            />
          </FormGroup>
        </Col>
      </Row>

      <br />

      <TextButton onClick={toggle}>
        {isOpen ? (
          <>
            <FiIcons.FiChevronUp /> Hide Parameters
          </>
        ) : (
          <>
            <FiIcons.FiChevronDown /> Expand Parameters
          </>
        )}
      </TextButton>
      <br />
      <br />

      <Collapse isOpen={isOpen}>
        {props.module?.type === 'iterated' ? (
          <InteratedModuleInputs module={props.module} />
        ) : (
          <BlockModuleInputs module={props.module} />
        )}
      </Collapse>

      <FormGroup>
        <Label>Agent ID</Label>
        <CustomInput
          type='text'
          placeholder='e5033284b0e2cf30622cfbe401559fe8'
          value={props.agentId}
          onChange={props.onAgentIdChange}
        />
        <FormText>The Agent ID is received when the Agent is executed.</FormText>
      </FormGroup>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Nodes</Label>
            <Select
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              components={animatedComponents}
              name='nodes'
              value={props.nodes}
              onChange={props.onNodesChange}
              options={props.nodesState.map(nodeStateItem => ({
                value: nodeStateItem,
                label: nodeStateItem,
              }))}
              className='custom-input'
            />
            <FormText>Config will be applied on the selected Nodes.</FormText>
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Groups</Label>
            <Select
              isMulti
              isSearchable
              closeMenuOnSelect={false}
              components={animatedComponents}
              name='groups'
              value={props.groups}
              onChange={props.onGroupsChange}
              options={props.groupsState.map(groupStateItem => ({
                value: groupStateItem,
                label: groupStateItem,
              }))}
              className='custom-input'
            />
            <FormText>Config will be applied on all Nodes in the selected Groups.</FormText>
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label>Description</Label>
        <CustomInput
          type='textarea'
          placeholder='Description'
          rows={props?.description?.split('\n')?.length || 1}
          value={props.description}
          onChange={props.onDescriptionChange}
        />
      </FormGroup>

      <FormGroup>
        <Label>Version</Label>
        <CustomInput type='text' placeholder='v1.0.0' value={props.version} onChange={props.onVersionChange} />
      </FormGroup>

      <hr />
      <FormGroup>
        <CustomButton type='submit' color='primary'>
          Save
        </CustomButton>
      </FormGroup>
    </>
  );
};

export default ConfigsFormInputs;
