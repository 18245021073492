export const parseNodeTags = str => {
  const tagsObject = {};

  try {
    str.split('\n').forEach(keyValue => {
      try {
        const [key, value] = keyValue.split('=');
        if (value.includes(',')) {
          // If the value contains a comma, split it into an array
          tagsObject[key] = value.split(',');
        } else {
          tagsObject[key] = value;
        }
      } catch (e) {}
    });
    delete tagsObject[''];
  } catch (e) {
    delete tagsObject[''];
  }

  return tagsObject;
};

export const unparseNodeTags = obj => {
  let tagsString = '';

  try {
    delete obj[''];
    for (const key in obj) {
      const value = obj[key];
      if (Array.isArray(value)) {
        // If the value is an array, join its elements with commas
        tagsString = tagsString.concat(`${key}=${value.join(',')}\n`);
      } else {
        tagsString = tagsString.concat(`${key}=${value}\n`);
      }
    }
  } catch (e) {
    delete obj[''];
  }

  return tagsString;
};
