///////////////////////////////////////////////////////////////////////////////////MODULES
import { useNavigate } from 'react-router-dom';
import {
  Row,
  Col,
  CustomToast,
  CustomButton,
  CustomToastHeader,
  CustomToastBody,
  CustomCard,
  MainImage,
} from '@ibiliaze/reactstrap';
import YouTube from 'react-youtube';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import SubscriptionPortal from '../layout/SubscriptionPortal';
import Banner from '../layout/Banner';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import scroll from '../../utils/scroll';
//////////////////////////////////////////////////////////////////////////////////////////

const DashboardPage = () => {
  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <>
      <MainImage
        imgPath={constants.mainPic}
        logoPath={constants.mainPicLogo}
        logoHeight='10%'
        logoLeft='20px'
        logoBottom='20px'
        facebookHandle={constants.facebook}
        instagramHandle={constants.instagram}
        youtubeChannelId={constants.youtube}
        linkedinHandle={constants.linkedin}
        slogan={constants.slogan}
        title={constants.cust.toUpperCase()}
        onButtonClick={_ => scroll(document.getElementById('services-section')?.offsetTop)}
        buttonText={constants.mainPicButton}
        infoTop='60%'
        imgBrightness='.35'
        imgHeight='600px'
        imgMobileHeight='300px'
        titleLetterSpacing='0rem'
        titleCentre={true}
        sloganCentre={true}
        mobileWidth={700}
      />

      <section id='about-section' className='page p-t-xl p-b-xl'>
        <Banner />

        <h1 className='t-align-c'>
          Does Your Infrastructure Need an <span className='text-gradient'>Upgrade</span>?
        </h1>
        <Row>
          <Col md={4}>
            <h3>
              What <span className='text-gradient'>NodeConfig</span> will do
            </h3>
            <br />
            <CustomToast>
              <CustomToastHeader>Efficiency Boost</CustomToastHeader>
              <CustomToastBody>
                Automation and orchestration services from NodeConfig can significantly enhance a company's operational
                efficiency by automating routine server tasks, reducing manual interventions, and minimizing human
                errors
              </CustomToastBody>
            </CustomToast>

            <CustomToast>
              <CustomToastHeader>Scalability Made Simple</CustomToastHeader>
              <CustomToastBody>
                As businesses expand, their server infrastructure needs to scale accordingly. NodeConfig's services
                enable seamless scalability by automating the provisioning and configuration of new servers, ensuring a
                smooth growth trajectory.
              </CustomToastBody>
            </CustomToast>

            <CustomToast>
              <CustomToastHeader>Resource Optimization</CustomToastHeader>
              <CustomToastBody>
                With NodeConfig's orchestration, companies can optimize resource allocation, ensuring that computing
                power, storage, and networking resources are allocated precisely where needed, thereby improving
                performance and cost-effectiveness.
              </CustomToastBody>
            </CustomToast>
          </Col>
          <Col md={4}>
            <div className='t-align-c p-t-xl p-b-xl'>
              <img src='/img/logo-no-bg.png' style={{ width: '90%' }} alt='logo' />
            </div>
          </Col>
          <Col md={4}>
            <CustomCard
              imgSrc='/img/servers.gif'
              alt='servers'
              title='Introducing the NodeAgent'
              text='NodeAgent, developed by NodeConfig, is a lightweight orchestration agent installed on servers. It acts as a central control hub for seamless automation, monitoring, provisioning, and configuration of servers. NodeAgent optimizes resource usage, scales efficiently, and enhances overall performance, ensuring synchronized operations across the network. This intelligent solution empowers responsive IT management, streamlining server automation for enhanced efficiency.'
              onCardClickHandler={_ => window.open(process.env.REACT_APP_NODEAGENT_DOWNLOAD_LINK)}
              multiclick={false}
            />
            <CustomButton
              size='lg'
              color='primary'
              onClick={_ => window.open(process.env.REACT_APP_NODEAGENT_DOWNLOAD_LINK)}
            >
              Download NodeAgent
            </CustomButton>
          </Col>
        </Row>
      </section>

      <div className='page p-t-xl p-b-xl'>
        <h1 className='t-align-c'>
          Control Your Infrastructure with your <span className='text-gradient'>Phone</span>
        </h1>
        <Row>
          <Col md={3}>
            <img alt='phone' src='/img/phone.png' style={{ width: '100%' }} />
          </Col>
          <Col md={9}>
            NodeConfig revolutionizes IT infrastructure management by placing the control firmly in the palm of your
            hand. Through this innovative app, your phone transforms into a centralized hub, allowing you to configure,
            monitor, and optimize your network nodes effortlessly. With NodeConfig, you can tweak settings, receive
            real-time status updates, and even execute remote troubleshooting tasks. The app's intuitive interface
            empowers you to swiftly respond to changing demands, ensure security protocols are upheld, and swiftly
            address any potential concerns. By harnessing the power of NodeConfig, you seamlessly wield the authority to
            orchestrate your IT infrastructure with unprecedented ease, regardless of your physical location.
          </Col>
        </Row>
      </div>

      <section id='pricing-section' className='page p-t-xl p-b-xl'>
        <h1 className='t-align-c'>Pricing</h1>
        <SubscriptionPortal onProductClick={_ => navigate('/auth')} />
      </section>

      <section id='guide-section' className='page p-t-xl p-b-xl'>
        <h1 className='t-align-c'>How to Use</h1>
        <YouTube
          style={{ boxShadow: '0 0.5rem 1rem rgba(0, 0, 0, 0.15)' }}
          videoId={process.env.REACT_APP_YT_GUIDE_ID}
          opts={{
            height: `700vh`,
            width: '100%',
            playerVars: {
              // https://developers.google.com/youtube/player_parameters
              autoplay: 0,
            },
          }}
        />
        <hr />

        <Row>
          <Col md={3}>
            <CustomCard
              imgSrc='/img/nodeagentCard.png'
              title='(Step 1) - Download the NodeAgent'
              text='Download the NodeAgent by clicking this card. NodeAgent is responsible for everything orchestration/automation related. To setup the NodeAgent, run the executable file and login to your nodeconfig.com account. After successful authentication, you will be presented with a welcome screen and the Agent ID. Copy the Agent ID to clipboard.'
              onCardClickHandler={_ => window.open(process.env.REACT_APP_NODEAGENT_DOWNLOAD_LINK)}
              multiclick={false}
            />
          </Col>
          <Col md={3}>
            <CustomCard
              imgSrc='/img/siteCard.png'
              title='(Step 2) - Create a Site'
              text='Click the "Sites" tab at the top of the screen, and create your geographical location (requires authentication).'
              onCardClickHandler={_ => navigate('/sites/edit')}
              multiclick={false}
            />
          </Col>
          <Col md={3}>
            <CustomCard
              imgSrc='/img/groupCard.png'
              title='(Step 3) - Create a Group'
              text='Click the "Groups" tab at the top of the screen, and create your first group. Group is a collection of servers (Nodes). Leave the Assigned Configs section empty if this is your first group (requires authentication).'
              onCardClickHandler={_ => navigate('/groups/edit')}
              multiclick={false}
            />
          </Col>
          <Col md={3}>
            <CustomCard
              imgSrc='/img/nodeCard.png'
              title='(Step 4) - Create a Node'
              text='Click the "Nodes" tab at the top of the screen, and create your first node. Node is a machine that is automated, it can be a physical server or a container. The Node requires an SSH server to be available as this is how the NodeAgent will communicate with the machine. Leave the Assigned Configs section empty if this is your first node. Choose a group to assign the node to (requires authentication).'
              onCardClickHandler={_ => navigate('/nodes/edit')}
              multiclick={false}
            />
          </Col>
          <Col md={3}>
            <CustomCard
              imgSrc='/img/moduleCard.png'
              title='(Step 5) - Create/Import a Module'
              text='Click the "Modules" tab at the top of the screen, and create your first module. You can also import a module from the Community page by filtering objects by modules (requires authentication).'
              onCardClickHandler={_ => navigate('/modules/edit')}
              multiclick={false}
            />
          </Col>
          <Col md={3}>
            <CustomCard
              imgSrc='/img/configCard.png'
              title='(Step 6) - Create a Config'
              text='Click the "Configs" tab at the top of the screen, and create your first config. Make sure to specify the module you just created/imported. Select the nodes & groups the config should apply to. Specify the Agent ID (copied value from step 1) (requires authentication).'
              onCardClickHandler={_ => navigate('/configs/edit')}
              multiclick={false}
            />
          </Col>
          <Col md={3}>
            <CustomCard
              imgSrc='/img/runCard.png'
              title='(Step 7) - Run the Config'
              text='Navigate back to the configs page and expand the config you wish to run. Click the "build" button for a dry-run and "run" button to run the config on the selected nodes (requires authentication).'
              onCardClickHandler={_ => navigate('/configs')}
              multiclick={false}
            />
          </Col>
          <Col md={3}>
            <CustomCard
              imgSrc='/img/historyCard.png'
              title='(Step 8) - Check your Job'
              text='Click the "History" tab at the top of the screen and check the output of the nodes from the config (requires authentication).'
              onCardClickHandler={_ => navigate('/history')}
              multiclick={false}
            />
          </Col>
        </Row>
      </section>
    </>
  );
};

export default DashboardPage;
