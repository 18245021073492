///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import { v4 as uuid } from 'uuid';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

export const getOrgSuccess = payload => ({ type: types.GET_ORG_SUCCESS, payload });
export const getOrgFailure = errorMessage => ({ type: types.GET_ORG_FAILURE, errorMessage });
export const getOrg = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/org` : `${resolveHost()}/api/org${endpoint}`);

    dispatch(getOrgSuccess(res.data));
  } catch (e) {
    dispatch(getOrgFailure(e.message));
  }
};

// Register
export const registerSuccess = payload => ({ type: types.REGISTER_SUCCESS, payload });
export const registerFailure = errorMessage => ({ type: types.REGISTER_FAILURE, errorMessage });
export const register = data => async dispatch => {
  try {
    dispatch(setAlert('Redirecting to checkout...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/create-checkout-session`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.REGISTER_SUCCESS));
    if ('url' in res.data) window.location = res.data.url;
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.REGISTER_FAILURE));
    dispatch(registerFailure(e.message));
  }
};

// Login
export const loginSuccess = payload => ({ type: types.LOGIN_SUCCESS, payload });
export const loginFailure = errorMessage => ({ type: types.LOGIN_FAILURE, errorMessage });
export const login = data => async dispatch => {
  localStorage.setItem('nodeconfig_ls_username', data.username);
  localStorage.setItem('nodeconfig_ls_clientId', uuid());

  try {
    dispatch(setAlert('Signing in...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/org/token`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.LOGIN_SUCCESS));
    dispatch(loginSuccess(res.data));
    dispatch(getOrg());
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.LOGIN_FAILURE));
    dispatch(loginFailure(e.message));
  }
};

// Logout
export const logoutSuccess = payload => ({ type: types.LOGOUT_SUCCESS, payload });
export const logoutFailure = errorMessage => ({ type: types.LOGOUT_FAILURE, errorMessage });
export const logout = () => async dispatch => {
  try {
    dispatch(setAlert('Signing out...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/org/logout`);

    dispatch(setAlert(res.data.message, false, res.status, types.LOGOUT_SUCCESS));
    dispatch(logoutSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.LOGOUT_FAILURE));
    dispatch(logoutFailure(e.message));
  }
};

export const logoutAll = () => async dispatch => {
  try {
    dispatch(setAlert('Signing out...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/org/logout/all`);

    dispatch(setAlert(res.data.message, false, res.status, types.LOGOUT_SUCCESS));
    dispatch(logoutSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.LOGOUT_FAILURE));
    dispatch(logoutFailure(e.message));
  }
};

// PUT /api/org
export const putOrgSuccess = payload => ({ type: types.PUT_ORG_SUCCESS, payload });
export const putOrgFailure = errorMessage => ({ type: types.PUT_ORG_FAILURE, errorMessage });
export const putOrg = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the account...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/org`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_ORG_SUCCESS));
    dispatch(putOrgSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_ORG_FAILURE));
    dispatch(putOrgFailure(e.message));
  }
};

// PUT /api/org/password
export const putOrgPassword = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the password...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/org/password`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_ORG_SUCCESS));
    dispatch(putOrgSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.PUT_ORG_FAILURE));
    dispatch(putOrgFailure(e.message));
  }
};

// DELETE /api/org
export const deleteOrgSuccess = payload => ({ type: types.DELETE_ORG_SUCCESS, payload });
export const deleteOrgFailure = errorMessage => ({ type: types.DELETE_ORG_FAILURE, errorMessage });
export const deleteOrg = () => async dispatch => {
  try {
    dispatch(setAlert('Deleting the account...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/org`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_ORG_SUCCESS));
    dispatch(deleteOrgSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.DELETE_ORG_FAILURE));
    dispatch(deleteOrgFailure(e.message));
  }
};
