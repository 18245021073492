///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, memo } from 'react';
import { Collapse, CustomButton, Alert, UncontrolledTooltip } from '@ibiliaze/reactstrap';
import errorParser, { messageParser } from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from '../../utils/icons';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
import { copyToClipboard } from '../../utils/copyToClipboard';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ModuleListItem from './ModuleListItem';
import ConfigListItem from './ConfigListItem';
import NodeListItem from './NodeListItem';
import SiteListItem from './SiteListItem';
import GroupListItem from './GroupListItem';
//////////////////////////////////////////////////////////////////////////////////////////

const ObjectListItem = ({ object }) => {
  // State
  const [isOpen, setIsOpen] = useState(false);
  const [clientMessage, setClientMessage] = useState({ text: '', loading: false, color: 'secondary' });

  // Toggle functions
  const toggle = _ => setIsOpen(!isOpen);

  // onClick functions
  const onSaveClick = _ => {
    setClientMessage(c => ({ ...c, loading: true }));
    const req = async () => {
      try {
        const response = await apiRequest({ endpoint: object.objectType, method: 'POST', data: object.object });
        if (response.isError) throw new Error(response.message);
        setClientMessage(c => ({ text: messageParser(response), loading: false, color: 'success' }));
      } catch (e) {
        setClientMessage(c => ({ text: errorParser(e), loading: false, color: 'danger' }));
      }
    };
    req();
  };
  const onDeleteClick = _ => {
    setClientMessage(c => ({ ...c, loading: true }));
    const req = async () => {
      try {
        const response = await apiRequest({ endpoint: 'community', method: 'DELETE', params: `/${object._id}` });
        if (response.isError) throw new Error(response.message);
        setClientMessage(c => ({ text: messageParser(response), loading: false, color: 'success' }));
      } catch (e) {
        setClientMessage(c => ({ text: errorParser(e), loading: false, color: 'danger' }));
      }
    };
    req();
  };
  const onVerifyClick = _ => {
    setClientMessage(c => ({ ...c, loading: true }));
    const req = async () => {
      try {
        const response = await apiRequest({ endpoint: 'community', method: 'GET', params: `/verify/${object._id}` });
        if (response.isError) throw new Error(response.message);
        setClientMessage(c => ({ text: messageParser(response), loading: false, color: 'success' }));
      } catch (e) {
        setClientMessage(c => ({ text: errorParser(e), loading: false, color: 'danger' }));
      }
    };
    req();
  };
  const onCopyClick = _ => {
    copyToClipboard(JSON.stringify(object.object, null, 2));
    setClientMessage(c => ({ text: 'Copied to clipboard', loading: false, color: 'success' }));
  };

  // JSX
  return (
    <div key={object._id} className='list-item'>
      <h6>
        {object?.verified && <FiIcons.FiStar style={{ color: '#827114' }} />} {object.objectName}
      </h6>
      <div className='list-item__p1'>{object.objectType}</div>
      <div>
        <br />
        {clientMessage.text && <Alert color={clientMessage.color}>{clientMessage.text}</Alert>}
      </div>
      <div className='list-item__p1'>by: {object.creator}</div>
      {object?.verified && (
        <div className='list-item__p1' style={{ color: '#827114' }}>
          Verified {object.objectType}
        </div>
      )}
      <div onClick={toggle} className='text-button'>
        expand
      </div>
      <Collapse isOpen={isOpen}>
        <br />
        {/* Start of Buttons Column */}
        <CustomButton outline onClick={onSaveClick} color='success' id={`save-${object._id}`}>
          <FiIcons.FiUploadCloud />
        </CustomButton>
        <UncontrolledTooltip placement='bottom' target={`save-${object._id}`}>
          Save
        </UncontrolledTooltip>
        <CustomButton outline color='primary' onClick={onCopyClick} id={`copy-${object._id}`}>
          <FiIcons.FiCopy />
        </CustomButton>
        <UncontrolledTooltip placement='bottom' target={`copy-${object._id}`}>
          Copy
        </UncontrolledTooltip>
        <CustomButton outline onClick={onDeleteClick} id={`delete-${object._id}`} color='danger'>
          <FiIcons.FiTrash />
        </CustomButton>
        <UncontrolledTooltip placement='bottom' target={`delete-${object._id}`}>
          Delete
        </UncontrolledTooltip>
        <CustomButton outline onClick={onVerifyClick} id={`verify-${object._id}`} color='info'>
          <FiIcons.FiStar />
        </CustomButton>
        <UncontrolledTooltip placement='bottom' target={`verify-${object._id}`}>
          Verify
        </UncontrolledTooltip>
        {/* End of Buttons Column */}

        <hr />
        {object.objectType === 'module' ? (
          <ModuleListItem module={object.object} />
        ) : object.objectType === 'config' ? (
          <ConfigListItem config={object.object} />
        ) : object.objectType === 'node' ? (
          <NodeListItem node={object.object} />
        ) : object.objectType === 'site' ? (
          <SiteListItem site={object.object} />
        ) : object.objectType === 'group' ? (
          <GroupListItem group={object.object} />
        ) : null}
      </Collapse>
    </div>
  );
};

export default memo(ObjectListItem);
