///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomButton, Alert, UncontrolledTooltip } from '@ibiliaze/reactstrap';
import errorParser, { messageParser } from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from '../../utils/icons';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
//////////////////////////////////////////////////////////////////////////////////////////

const Item = ({ item }) => {
  // State
  const [clientMessage, setClientMessage] = useState({ text: '', loading: false, color: 'secondary' });

  // History
  const navigate = useNavigate();

  // onClick functions
  const onDeleteClick = async _ => {
    try {
      const response = await apiRequest({ endpoint: 'history', method: 'DELETE', params: `/${item._id}` });
      if (response.isError) throw new Error(response.message);
      setClientMessage(_ => ({ text: messageParser(response), loading: false, color: 'success' }));
    } catch (e) {
      setClientMessage(_ => ({ text: errorParser(e), loading: false, color: 'danger' }));
    }
  };

  // JSX
  return (
    <div className='list-item'>
      <h6 onClick={_ => navigate(`/results/${item._id}`)} className='text-button'>
        {item.itemName}
      </h6>
      <div className='list-item__p1'>{item?.task}</div>
      <div className='list-item__p1'>{item._id}</div>
      <div>
        <br />
        <div className='list-item__p1'>{item?.createdAt}</div>
        {clientMessage.text && (
          <>
            <br />
            <Alert color={clientMessage.color}>{clientMessage.text}</Alert>
          </>
        )}
        <br />
        <CustomButton
          outline
          color='primary'
          onClick={_ => window.open(`/results/${item._id}`)}
          id={`item-open-${item._id}`}
        >
          <FiIcons.FiExternalLink />
        </CustomButton>
        <UncontrolledTooltip placement='bottom' target={`item-open-${item._id}`}>
          Open Window
        </UncontrolledTooltip>

        <CustomButton outline color='danger' onClick={onDeleteClick} id={`item-delete-${item._id}`}>
          <FiIcons.FiTrash />
        </CustomButton>
        <UncontrolledTooltip placement='bottom' target={`item-delete-${item._id}`}>
          Delete
        </UncontrolledTooltip>
      </div>
    </div>
  );
};

export default memo(Item);
