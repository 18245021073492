///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormGroup, Row, Col, Label, CustomInput } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const ConfigListItem = ({ config }) => (
  <Row>
    <Col md={4}>
      <FormGroup>
        <Label>Config Name</Label>
        <CustomInput
          disabled
          type='text'
          placeholder='Config Name'
          value={config && config.configName}
          onChange={e => {}}
        />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Label>Module</Label>
        <CustomInput disabled type='text' placeholder='Module' value={config && config.moduleName} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Label>Version</Label>
        <CustomInput disabled type='text' placeholder='Version' value={config && config.version} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={12}>
      <FormGroup>
        <Label>Description</Label>
        <CustomInput
          disabled
          type='textarea'
          rows={config?.description?.split('\n')?.length || 1}
          placeholder='Description'
          value={config && config.description}
          onChange={e => {}}
        />
      </FormGroup>
    </Col>
  </Row>
);

export default ConfigListItem;
