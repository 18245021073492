///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormGroup, Row, Col, Label, CustomInput } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const GroupListItem = ({ group }) => (
  <Row>
    <Col md={12}>
      <FormGroup>
        <Label>Group Name</Label>
        <CustomInput
          disabled
          type='text'
          placeholder='Group Name'
          value={group && group.groupName}
          onChange={e => {}}
        />
      </FormGroup>
    </Col>
    <Col md={12}>
      <FormGroup>
        <Label>Description</Label>
        <CustomInput
          disabled
          type='textarea'
          rows={group?.description?.split('\n')?.length || 1}
          placeholder='Description'
          value={group && group.description}
          onChange={e => {}}
        />
      </FormGroup>
    </Col>
  </Row>
);

export default GroupListItem;
