///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Spinner } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import NodeContext from "../../context/ObjectContext";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postNode, putNode } from "../../actions/nodes";
import { getGroups } from "../../actions/groups";
import { getSites } from "../../actions/sites";
import { getConfigs } from "../../actions/configs";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from "../../utils/httpRequest";
import { parseNodeTags, unparseNodeTags } from "../../utils/parseNodeTags";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import NodesFormInputs from "./NodesFormInputs";
import { NodeHowto } from "../layout/Howto";
//////////////////////////////////////////////////////////////////////////////////////////

const NodesForm = ({
  auth,
  sites,
  configs,
  groupsState,
  getSites,
  getConfigs,
  getGroups,
  postNode,
  putNode,
}) => {
  // State
  const [nodeId, setNodeId] = useState("");
  // For inputs
  const [nodeName, setNodeName] = useState("");
  const [description, setDescription] = useState("");
  const [assignedConfigs, setAssignedConfigs] = useState([]);
  const [groups, setGroups] = useState([]);
  const [siteName, setSiteName] = useState("");
  const [type, setType] = useState("");
  const [platform, setPlatform] = useState("");
  const [layer, setLayer] = useState("");
  const [host, setHost] = useState("");
  const [sshUsername, setSshUsername] = useState("");
  const [sshPassword, setSshPassword] = useState("");
  const [sshPort, setSshPort] = useState("");
  const [tags, setTags] = useState("");

  // History
  const navigate = useNavigate();

  // onChange functions
  const onNodeNameChange = (e) => setNodeName(e.target.value);
  const onDescriptionChange = (e) => setDescription(e.target.value);
  const onAssignedConfigsChange = (e) => setAssignedConfigs(e); // from react-select
  const onGroupsChange = (e) => setGroups(e); // from react-select
  const onSiteNameChange = (e) => setSiteName(e.value); // from react-select
  const onTypeChange = (e) => setType(e.target.value);
  const onPlatformChange = (e) => setPlatform(e.value); // from react-select
  const onLayerChange = (e) => setLayer(e.value); // from react-select
  const onHostChange = (e) => setHost(e.target.value);
  const onSshUsernameChange = (e) => setSshUsername(e.target.value);
  const onSshPasswordChange = (e) => setSshPassword(e.target.value);
  const onSshPortChange = (e) => setSshPort(e.target.value);
  const onTagsChange = (e) => setTags(e.target.value);

  // Lifecycle hooks
  useEffect(() => {
    const edit = window.location.pathname.replace("/nodes/edit", "");
    !!edit && setNodeId(edit.replace("/", ""));

    const req = async () => {
      try {
        await getSites("?select=siteName");
        await getConfigs("?select=configName");
        await getGroups("?select=groupName");

        if (!!edit) {
          const response = await apiRequest({
            endpoint: "node",
            method: "GET",
            params: edit,
          });
          if (response.isError) throw new Error(response.message);
          setNodeName(response?.nodeName);
          setDescription(response?.description);
          setAssignedConfigs(
            response?.assignedConfigs
              ? response?.assignedConfigs.map((config) => ({
                  value: config,
                  label: config,
                }))
              : null
          );
          setGroups(
            response?.groups
              ? response?.groups.map((group) => ({
                  value: group,
                  label: group,
                }))
              : null
          );
          setSiteName(response?.siteName);
          setType(response?.type);
          setPlatform(response?.platform);
          setLayer(response?.layer);
          setHost(response?.host);
          setSshUsername(response?.sshUsername);
          setSshPassword(response?.sshPassword);
          setSshPort(response?.sshPort);
          setTags(response?.tags ? unparseNodeTags(response?.tags) : "");
        }
      } catch (e) {
        setNodeId("");
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    if (!auth.isAuthenticated) return navigate.push("/auth");

    const req = nodeId ? putNode : postNode;
    await req({
      nodeId: nodeId ? nodeId : undefined,
      nodeName: nodeId ? undefined : nodeName,
      description,
      assignedConfigs:
        assignedConfigs !== "" && assignedConfigs
          ? assignedConfigs.map((config) => config.value)
          : [],
      groups: groups !== "" && groups ? groups.map((group) => group.value) : [],
      siteName,
      type,
      platform,
      layer,
      host,
      sshUsername,
      sshPassword,
      sshPort,
      tags: parseNodeTags(tags),
    });
  };

  // JSX
  return (
    <NodeContext.Provider
      value={{
        nodeId,
        sites,
        configs,
        groupsState,
        nodeName,
        description,
        assignedConfigs,
        groups,
        siteName,
        type,
        platform,
        layer,
        host,
        sshUsername,
        sshPassword,
        sshPort,
        tags,
        onNodeNameChange,
        onDescriptionChange,
        onAssignedConfigsChange,
        onGroupsChange,
        onSiteNameChange,
        onTypeChange,
        onPlatformChange,
        onLayerChange,
        onHostChange,
        onSshUsernameChange,
        onSshPasswordChange,
        onSshPortChange,
        onTagsChange,
      }}
    >
      <div className="below-header">
        <h1 className="t-align-c no-m">Node Editor</h1>
        <section className="page p-b-m p-t-m" style={{ minHeight: "100vh" }}>
          <Form onSubmit={onSubmit}>
            {nodeId && !nodeName ? (
              <Spinner color="primary" className="object-list__spnr" />
            ) : (
              <NodesFormInputs />
            )}
          </Form>
          <NodeHowto />
        </section>
      </div>
    </NodeContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  message: state.message,
  auth: state.auth,
  sites: state.sites,
  configs: state.configs,
  groupsState: state.groups,
});

export default connect(mapStateToProps, {
  getSites,
  getConfigs,
  getGroups,
  postNode,
  putNode,
})(NodesForm);
