///////////////////////////////////////////////////////////////////////////////////MODULES
import { Link } from 'react-router-dom';
//////////////////////////////////////////////////////////////////////////////////////////

const Layout = ({ img, children }) => (
  <div style={{ opacity: '0.6' }} className='t-align-c'>
    <br />
    <br />
    <h5>How-to Guide</h5>
    <br />
    <img alt={img} src={img} style={{ width: '20vw' }} />
    <br />
    <br />
    {children.map(para => para)}
    <br />
  </div>
);

const SiteHowto = () => (
  <Layout img='/img/site.png'>
    <p>
      - Site Name is a global name assigned to the site. The name will be required when creating nodes
      (machines/containers), as each node should be assigned to a site
    </p>
    <p>- Site Name is a required input. The rest of the inputs are optional parameters</p>
  </Layout>
);

const GroupHowto = () => (
  <Layout img='/img/group.png'>
    <p>- Group Name is a global name assigned to the group. Group is a collection of nodes (servers/containers)</p>
    <p>- Group Name is a required input. The rest of the inputs are optional parameters</p>
    <p>- Any configs that are assigned to a group will be ran on all nodes in that group</p>
  </Layout>
);

const NodeHowto = () => (
  <Layout img='/img/node.png'>
    <p>- Node Name is a global name assigned to the node. Node can be a physical machine, VM or a container</p>
    <p>
      - Node Name, Site Name, Platform, Layer, Host, SSH Username, SSH Password, SSH Port are required inputs. The rest
      of the inputs are optional parameters
    </p>
    <p>- Host can be the IP address of the target machine/container</p>
  </Layout>
);

const ModuleHowto = () => (
  <Layout img='/img/module.png'>
    <p>
      - Module Name is a global name assigned to the module. Module dictates how a config should run and there are 2
      types of modules: Block, Iterated
    </p>
    <p>
      - Block modules are simple, you create an input and that input will be available when using the module in a
      config. Each input is called a key and its value needs to be defined properly at key definition. A visual JSON
      representation of an Iterated module would be as such:
    </p>
    <pre style={preStyles}>{blockModuleExample}</pre>
    <p>
      - Think of Iterated modules as a set number of Block modules. To get started with an Iterated module, you first
      have to specify the iterator. The iterator is what NodeAgent engine will use to loop a list/array over. An example
      of an iterator would be "commandSets", subsequently, the keys defined in iterator could be "commandSetDescription"
      and "commands". A visual JSON representation of an Iterated module would be as such:
    </p>
    <pre style={preStyles}>{iteratedModuleExample}</pre>
    <p>
      - Once you have defined your keys, you can write your template in Jinja2 format. To access the keys in a Block
      module, use the format {'{{ <key> }}'}. If you want to access the keys in an Iterated module, you have to loop
      over the sets with: {'{% for set_item in <iterator> %}'}, and access the key: {'{{ set_item.<key> }}'}
    </p>
    <p>- You can access the node tags via this syntax in the Jinja2 template: {'{{node.tags.<key>}}'}</p>
    <p>Below is an example Jinja2 template:</p>
    <pre style={preStyles}>{jinja2Example}</pre>
    <p>- The Label in key definition refers to the input label in the config page when using that key</p>
  </Layout>
);

const ConfigHowto = () => (
  <Layout img='/img/config.png'>
    <p>
      - Config Name is a global name assigned to the config. Configs build on top of modules to accumulate commands that
      will run on nodes
    </p>
    <p>
      - Config Name and Module are required inputs, including the required inputs from the module keys. The rest of the
      inputs are optional parameters
    </p>
    <p>- Select a module and fill all the required inputs by the module</p>
    <p>
      - Select nodes that the config should apply to. Running the config will apply the commands on the target host.
      Additionally to save time, specify a common group instead
    </p>
    <p>
      - For a dry run feature utilise the "build" feature of the config, This will create the complete set of commands
      that will be applied to each node
    </p>
    <p>- Browse to the History page to view the outputs of config runs and builds from the NodeAgent</p>
  </Layout>
);

const CommunityHowto = () => (
  <Layout img='/img/community.png'>
    <p>Import objects created by the community</p>
    <p></p>
  </Layout>
);

const HistoryHowto = () => (
  <Layout img='/img/history.png'>
    <p>Complete history of jobs ran by NodeAgent will be displayed in this page</p>
    <p></p>
  </Layout>
);

const OrgHowto = () => (
  <Layout img='/img/org.png'>
    <p>
      You have successfully subscribed to NodeConfig. You can now <Link to='/auth'> sign in</Link>
    </p>
    <p></p>
  </Layout>
);

const blockModuleExample = `{
  "key1": "value1",
  "key2": [
    "value2",
    "value3
  ]
}`;

const iteratedModuleExample = `{
  "commandSets": [
    {
      "commandSetDescription": "my 1st desc",
      "commands": [
        "#!/bin/bash",
        "ls"
      ]
    },
    {
      "commandSetDescription": "my 2nd desc",
      "commands": [ "pwd" ]
    }
  ]
}`;

const jinja2Example = `{% for set_item in commandSets %}
# Set description: {{ set_item.commandSetDescription }}
# Commands for the node {{node.nodeName}}:
{% for command in set_item.commands %}
{{ command }}
{% endfor %}
{% endfor %}`;

const preStyles = {
  textAlign: 'left',
  backgroundColor: 'black',
  padding: '20px',
  color: 'white',
  borderRadius: '5px',
  maxWidth: '500px',
  marginLeft: 'auto',
  marginRight: 'auto',
  fontSize: '0.9rem',
};

export { SiteHowto, GroupHowto, NodeHowto, ModuleHowto, ConfigHowto, CommunityHowto, HistoryHowto, OrgHowto };
