/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////////MODULES DEFAULT STATE
const modulesDefaultState = [];
/////////////////////////////////////////////////////////////////////////////MODULES REDUCER
const modules = (state = modulesDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_MODULES_SUCCESS:
      return [...action.modules];
    case types.GET_MODULES_FAILURE:
      return [...state];

    // POST request
    case types.POST_MODULE_SUCCESS:
      return [...state, action.module];
    case types.POST_MODULE_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_MODULE_SUCCESS:
      return state.map(module => {
        if (module._id === action.module._id) {
          return { ...module, ...action.module };
        } else {
          return module;
        }
      });
    case types.PUT_MODULE_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_MODULE_SUCCESS:
      return state.filter(module => module._id !== action.module._id);
    case types.DELETE_MODULE_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default modules;
