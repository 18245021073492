///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from "../utils/httpRequest";
//////////////////////////////////////////////////////////////////////////////////////////

export const useGetItems = (inputs, page, search, setLoadSpinner) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const req = async () => {
      setLoadSpinner(true);

      try {
        const response = await apiRequest({
          endpoint: "history",
          method: "GET",
          params: `?limit=8&skip=${8 * (page - 1)}${
            inputs.task === "all" ? "" : `&task=${inputs.task}`
          }&itemName=${inputs.itemName}&sortBy=${
            inputs.createdAt
          }&select=itemName objectType objectId task createdAt updatedAt`,
          data: null,
        });
        if (response.isError) throw new Error(response.message);
        if (!Array.isArray(response))
          throw new Error("Failed to fetch history");

        setItems(response);
        setLoadSpinner(false);
      } catch (e) {
        setItems([]);
        setLoadSpinner(false);
      }
    };

    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs.createdAt, inputs.task, search, page]);

  return [items, setItems];
};
