///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse } from '@ibiliaze/reactstrap';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ObjectListItemButtons from '../buttons/ObjectListItemButtons';
//////////////////////////////////////////////////////////////////////////////////////////

const SitesListItem = ({ auth, site, deleteSite }) => {
  // State
  const [isOpen, setIsOpen] = useState(false);

  // Toggle functions
  const toggle = _ => setIsOpen(!isOpen);

  // History
  const history = useNavigate();

  // onClick functions
  const onEditClick = _ => history(`/sites/edit/${site._id}`);

  // JSX
  return (
    <div className='list-item'>
      <h6 onClick={onEditClick} className='text-button'>
        {site.siteName}
      </h6>
      <div className='list-item__p1'>{site._id}</div>
      <br />
      <div className='list-item__p1'>Department: {site?.department}</div>
      <div onClick={toggle} className='text-button'>
        expand
      </div>
      <Collapse isOpen={isOpen}>
        <hr />
        <ObjectListItemButtons auth={auth} object={site} objectType='site' deleteObject={deleteSite} />
      </Collapse>
    </div>
  );
};

export default SitesListItem;
