///////////////////////////////////////////////////////////////////////////////////MODULES
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Label,
  FormGroup,
  Spinner,
  CustomButton,
  InputGroup,
  CustomInput,
} from "@ibiliaze/reactstrap";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getSites, deleteSite } from "../../actions/sites";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import SiteListItem from "../sites/SitesListItem";
import Paging from "../layout/Paging";
import { SiteHowto } from "../layout/Howto";
//////////////////////////////////////////////////////////////////////////////////////////

const SitesPage = ({ auth, sites, getSites, deleteSite }) => {
  // State
  const [search, setSearch] = useState(false);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [inputs, setInputs] = useState({
    siteName: "",
    sortBy: "createdAt:desc",
  });
  const [page, setPage] = useState(1);

  // History
  const navigate = useNavigate();

  // onChange functions
  const onInputsChange = (e) =>
    setInputs((c) => ({
      ...c,
      [e.target.name]: e.label ? e.value : e.target.value,
    }));

  // onClick functions
  const onPageClick = (index) => (index < 1 ? setPage(1) : setPage(index));

  // Lifecycle hooks
  useEffect(() => {
    const req = async (_) => {
      setLoadSpinner(true);
      try {
        await getSites(
          `?limit=10&skip=${10 * (page - 1)}&siteName=${
            inputs.siteName
          }&sortBy=${inputs.sortBy}`
        );
        setLoadSpinner(false);
      } catch (e) {
        setLoadSpinner(false);
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, inputs.sortBy, page]);

  // JSX
  return (
    <div className="below-header">
      <h1 className="t-align-c no-m">Sites</h1>
      <section className="page p-b-m p-t-m" style={{ minHeight: "100vh" }}>
        <FormGroup>
          <CustomButton
            onClick={(_) => navigate("/sites/edit")}
            color="primary"
          >
            Create
          </CustomButton>
        </FormGroup>
        <hr />
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Sort</Label>
              <CustomInput
                type="select"
                name="sortBy"
                value={inputs.sortBy}
                onChange={onInputsChange}
              >
                <option>createdAt:asc</option>
                <option>createdAt:desc</option>
                <option>updatedAt:asc</option>
                <option>updatedAt:desc</option>
              </CustomInput>
            </FormGroup>
          </Col>
          <Col md={9}>
            <FormGroup>
              <Label>Search</Label>
              <InputGroup>
                <CustomInput
                  type="text"
                  placeholder="Site Name"
                  value={inputs.siteName}
                  name="siteName"
                  onChange={onInputsChange}
                />
                <CustomButton
                  onClick={(e) => setSearch(!search)}
                  color="secondary"
                >
                  Search
                </CustomButton>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <Paging page={page} onPageClick={onPageClick} />
        {loadSpinner ? (
          <Spinner color="primary" className="object-list__spnr" />
        ) : sites?.length !== 0 ? (
          sites.map((site) => (
            <SiteListItem
              key={site._id}
              auth={auth}
              site={site}
              deleteSite={deleteSite}
            />
          ))
        ) : (
          <SiteHowto />
        )}
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({ auth: state.auth, sites: state.sites });

export default connect(mapStateToProps, { getSites, deleteSite })(SitesPage);
