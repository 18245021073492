///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { Row, Col, Label, Spinner, FormGroup, CustomButton, InputGroup, CustomInput } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////HOOKS
import { useGetObjects } from '../../hooks/community';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ObjectListItem from '../community/ObjectListItem';
import Paging from '../layout/Paging';
import { CommunityHowto } from '../layout/Howto';
//////////////////////////////////////////////////////////////////////////////////////////

const CommunityPage = () => {
  // State
  const [search, setSearch] = useState(false);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [inputs, setInputs] = useState({ objectName: '', creator: '', objectType: 'all' });
  const [page, setPage] = useState(1);
  const [objects] = useGetObjects(inputs, page, search, setLoadSpinner);

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.label ? e.label : e.target.value }));

  // onClick functions
  const onPageClick = index => (index < 1 ? setPage(1) : setPage(index));

  // JSX
  return (
    <div className='below-header'>
      <h1 className='t-align-c no-m'>Community</h1>
      <section className='page p-b-m p-t-m' style={{ minHeight: '100vh' }}>
        <hr />
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Filter</Label>
              <CustomInput type='select' name='objectType' value={inputs.objectType} onChange={onInputsChange}>
                <option>config</option>
                <option>module</option>
                <option>site</option>
                <option>group</option>
                <option>node</option>
                <option>all</option>
              </CustomInput>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Creator</Label>
              <CustomInput
                type='text'
                placeholder='@IbiliAze'
                value={inputs.creator}
                name='creator'
                onChange={onInputsChange}
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Search</Label>
              <InputGroup>
                <CustomInput
                  type='text'
                  placeholder='IOS OSPF module'
                  value={inputs.objectName}
                  name='objectName'
                  onChange={onInputsChange}
                />
                <CustomButton onClick={e => setSearch(!search)} color='secondary'>
                  Search
                </CustomButton>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <Paging page={page} onPageClick={onPageClick} />
        {loadSpinner ? (
          <Spinner color='primary' className='object-list__spnr' />
        ) : (
          <>
            <CommunityHowto />
            {objects?.map(object => (
              <ObjectListItem key={object._id} object={object} />
            ))}
          </>
        )}
      </section>
    </div>
  );
};

export default CommunityPage;
