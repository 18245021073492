///////////////////////////////////////////////////////////////////////////////////MODULES
import { useNavigate } from 'react-router-dom';
import { CustomButton, PopoverBody, UncontrolledPopover, UncontrolledTooltip } from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from '../../utils/icons';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { copyToClipboard } from '../../utils/copyToClipboard';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import PublishButton from './PublishButton';
//////////////////////////////////////////////////////////////////////////////////////////

const ObjectListItemButtons = ({ auth, object, objectType, deleteObject }) => {
  // History
  const history = useNavigate();

  // onClick functions
  const onEditClick = _ => history(`/${objectType}s/edit/${object._id}`);
  const onCopyClick = _ => copyToClipboard(JSON.stringify(object, null, 2));
  const onDeleteClick = async _ => {
    if (!auth.isAuthenticated) return history('/auth');
    await deleteObject(object._id);
  };

  // JSX
  return (
    <>
      <CustomButton color='primary' onClick={onEditClick} id={`edit-${object._id}`} outline>
        <FiIcons.FiEdit />
      </CustomButton>
      <UncontrolledTooltip placement='bottom' target={`edit-${object._id}`}>
        Edit
      </UncontrolledTooltip>

      <CustomButton color='primary' onClick={onCopyClick} id={`copy-${object._id}`} outline>
        <FiIcons.FiCopy />
      </CustomButton>
      <UncontrolledTooltip placement='bottom' target={`copy-${object._id}`}>
        Copy
      </UncontrolledTooltip>
      <UncontrolledPopover
        trigger='focus'
        placement='top'
        target={`copy-${object._id}`}
        className='info-button-popover'
      >
        <PopoverBody>Copied</PopoverBody>
      </UncontrolledPopover>

      <PublishButton parameters={object} objectType={objectType} />

      <CustomButton color='danger' id={`delete-${object._id}`} outline>
        <FiIcons.FiTrash />
      </CustomButton>
      <UncontrolledTooltip placement='bottom' target={`delete-${object._id}`}>
        Delete
      </UncontrolledTooltip>
      <UncontrolledPopover
        trigger='focus'
        placement='top'
        target={`delete-${object._id}`}
        className='info-button-popover'
      >
        <PopoverBody>
          Are you sure you want to delete this object?
          <hr />
          <CustomButton onClick={onDeleteClick} color='danger'>
            Delete
          </CustomButton>{' '}
          <CustomButton onClick={e => {}} color='primary'>
            Cancel
          </CustomButton>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default ObjectListItemButtons;
