/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
////////////////////////////////////////////////////////////////////////AUTH DEFAULT STATE
const authDefaultState = {
  isAuthenticated: null,
  org: null,
};
//////////////////////////////////////////////////////////////////////////////AUTH REDUCER
const auth = (state = authDefaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    // Register
    case types.REGISTER_SUCCESS:
      return { ...state, org: null, isAuthenticated: false };

    // Login
    case types.LOGIN_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };

    // GET request
    case types.GET_ORG_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };

    // Auth failure & logout
    case types.LOGIN_FAILURE:
    case types.REGISTER_FAILURE:
    case types.GET_ORG_FAILURE:
    case types.LOGOUT_SUCCESS:
      localStorage.removeItem('nodeconfig_ls_clientId');
      localStorage.removeItem('nodeconfig_ls_username');
      return { ...state, org: null, isAuthenticated: false };
    case types.LOGOUT_FAILURE:
      return state;

    // PUT request
    case types.PUT_ORG_SUCCESS:
      return { ...state, org: payload.org };
    case types.PUT_ORG_FAILURE:
      return { ...state };

    // DELETE request
    case types.DELETE_ORG_SUCCESS:
      return { isAuthenticated: null, org: null };
    case types.DELETE_ORG_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default auth;
