///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Label,
  FormGroup,
  CustomButton,
  UncontrolledTooltip,
  Popover,
  PopoverBody,
  PopoverHeader,
  CustomInput,
} from '@ibiliaze/reactstrap';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from '../../utils/icons';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { sendMessage } from '../../utils/socket';
//////////////////////////////////////////////////////////////////////////////////////////

const PingButton = ({ auth, node, setAlert, setMessage }) => {
  // State
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [inputs, setInputs] = useState({ agentId: '', pingCount: '' });

  // Toggle functions
  const toggle = _ => setPopoverOpen(!popoverOpen);

  // History
  const history = useNavigate();

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onPingClick = _ => {
    if (!auth.isAuthenticated) return history('/auth');

    try {
      setMessage(`${node.nodeName} is being pinged by the NodeAgent`);
      sendMessage({
        destination: inputs.agentId,
        payload: {
          object: { ...node, ...inputs },
          taskDetails: {
            itemName: node.nodeName,
            task: 'ping',
            objectName: node.nodeName,
            objectType: 'node',
          },
        },
      });
    } catch (e) {
      setAlert(errorParser(e), true, 500, null);
    }
  };

  // JSX
  return (
    <>
      <CustomButton color='info' id={`ping-${node._id}`} outline>
        <FiIcons.FiCrosshair />
        <UncontrolledTooltip placement='bottom' target={`ping-${node._id}`}>
          Ping
        </UncontrolledTooltip>
      </CustomButton>
      <Popover
        trigger='legacy'
        isOpen={popoverOpen}
        toggle={toggle}
        placement='right'
        target={`ping-${node._id}`}
        className='info-button-popover'
      >
        <PopoverHeader>Ping details</PopoverHeader>
        <PopoverBody>
          <FormGroup>
            <Label>Agent ID *</Label>
            <CustomInput
              required
              type='text'
              placeholder='e5033284b0e2cf30622cfbe401559fe8'
              value={inputs.agentId}
              name='agentId'
              onChange={onInputsChange}
            />
          </FormGroup>

          <FormGroup>
            <Label>Ping count *</Label>
            <CustomInput
              required
              type='number'
              placeholder='4'
              value={inputs.pingCount}
              name='pingCount'
              onChange={onInputsChange}
            />
          </FormGroup>

          <CustomButton onClick={onPingClick} color='primary'>
            Ping
          </CustomButton>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default memo(PingButton);
