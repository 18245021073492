///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect, useContext } from "react";
import { isEqual } from "lodash";
import {
  Alert,
  FormText,
  Table,
  FormGroup,
  CustomInput,
  Label,
  Row,
  Col,
  Button,
  TextButton,
  CloseButton,
  InputGroup,
  Input,
} from "@ibiliaze/reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from "../../utils/icons";
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import ModuleContext from "../../context/ObjectContext";
//////////////////////////////////////////////////////////////////////////////////////////

const animatedComponents = makeAnimated();

const ModulesFormInputs = () => {
  // State
  const [paramIndex, setParamIndex] = useState(null);
  const [parameterInputs, setParameterCustomInputs] = useState([]);
  const [inputs, setCustomInputs] = useState({
    key: "",
    dataType: "",
    isRequired: false,
    default: "",
    superTrim: false,
    megaTrim: false,
    arrayItemsDataType: "",
    allowedValues: [],
    disAllowedValues: [],
    rawArray: true,
    label: "",
  });

  // Helper functions
  const resetAllInputs = () => {
    setCustomInputs({
      key: "",
      dataType: "",
      isRequired: false,
      default: "",
      superTrim: false,
      megaTrim: false,
      arrayItemsDataType: "",
      allowedValues: [],
      disAllowedValues: [],
      rawArray: true,
      label: "",
    });
  };

  // Context
  const props = useContext(ModuleContext);

  // onChange functions
  const onChangeHandler = (key, value) => {
    // Update the Param
    if (key === "allowedValues" || key === "disAllowedValues") {
      setCustomInputs({ ...inputs, [key]: value.split("\n") });
    } else {
      setCustomInputs({ ...inputs, [key]: value.label ? value.label : value });
    }
  };

  // onClick functions
  const onAddParamClick = (e) => {
    resetAllInputs();
    setParamIndex(null);
    props.setParameters([...parameterInputs, inputs]);
  };

  const onUpdateParamClick = (e) => {
    if (paramIndex || paramIndex === 0) {
      /**
       * Using this condition as "0" as an number is a falsy value:
       *  - !!0 === false
       */
      parameterInputs[paramIndex] = inputs;
      props.setParameters([...parameterInputs]);
      resetAllInputs();
    }
    setParamIndex(null);
  };

  const onRemoveParamClick = (e, index) => {
    resetAllInputs();
    setParamIndex(null);
    /**
     * This will return the removed element.
     * The actual array will be ammended.
     */
    parameterInputs.splice(index, 1);
    props.setParameters([...parameterInputs]);
  };

  const onCopyParamClick = (e, index, param) => {
    setParamIndex(index);
    setCustomInputs({
      key: param.key,
      dataType: param.dataType,
      isRequired: param.isRequired,
      default: param.default,
      superTrim: param.superTrim,
      megaTrim: param.megaTrim,
      arrayItemsDataType: param.arrayItemsDataType,
      allowedValues: param.allowedValues,
      disAllowedValues: param.disAllowedValues,
      rawArray: param.rawArray,
      label: param.label,
    });
  };

  const ClearInputsClick = (_) => resetAllInputs();

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (!isEqual(props.parameters, [])) {
        setParameterCustomInputs([
          ...props.parameters.map((parameterInputs) => ({
            key: parameterInputs.key,
            dataType: parameterInputs.dataType,
            isRequired: parameterInputs.isRequired,
            default: parameterInputs.default,
            superTrim: parameterInputs.superTrim,
            megaTrim: parameterInputs.megaTrim,
            arrayItemsDataType: parameterInputs.arrayItemsDataType,
            allowedValues: parameterInputs.allowedValues,
            disAllowedValues: parameterInputs.disAllowedValues,
            rawArray: parameterInputs.rawArray,
            label: parameterInputs.label,
          })),
        ]);
        resetAllInputs();
      } else {
        setParameterCustomInputs([]);
        resetAllInputs();
      }
    } catch (error) {}
  }, [props.parameters]);

  useEffect(() => {
    if (isEqual(props.parameters, [])) {
      resetAllInputs();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // JSX
  return (
    <>
      {props.moduleId && (
        <Alert color="warning">
          Some inputs will be disabled when editing Modules.
        </Alert>
      )}
      <Label>Module Name *</Label>
      <Row>
        <Col md={props.moduleId ? 6 : 12}>
          <FormGroup>
            <CustomInput
              autoFocus
              required
              disabled={props.moduleId ? true : false}
              placeholder="Module Name"
              value={props.moduleName}
              onChange={props.ModuleNameChange}
            />
          </FormGroup>
        </Col>

        {props.moduleId ? (
          <Col md={6}>
            <TextButton onClick={(e) => props.setModuleId(null)}>
              <FiIcons.FiPlus /> Use Module
            </TextButton>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <FormGroup>
        <Label>Description</Label>
        <CustomInput
          type="textarea"
          placeholder="Description"
          rows={props?.description?.split("\n")?.length || 1}
          value={props.description}
          onChange={props.DescriptionChange}
        />
      </FormGroup>

      <Row>
        <Col md={props.type === "iterated" ? 6 : 12}>
          <FormGroup>
            <Label>Type *</Label>
            <Select
              required
              isDisabled={props.moduleId ? true : false}
              components={animatedComponents}
              closeMenuOnSelect={true}
              value={props.type ? { value: props.type, label: props.type } : ""}
              onChange={props.TypeChange}
              options={[
                { value: "iterated", label: "iterated" },
                { value: "block", label: "block" },
              ]}
              className="custom-input"
            />
            <FormText>
              Block Modules contain a single list of commands. Iterated Modules
              contain multiple Block Modules within single Module.
            </FormText>
          </FormGroup>
        </Col>

        {props.type === "iterated" && (
          <Col md={6}>
            <FormGroup>
              <Label>Iterator *</Label>
              <CustomInput
                required
                disabled={props.moduleId ? true : false}
                placeholder="Iterator"
                value={props.iterator}
                onChange={props.IteratorChange}
              />
            </FormGroup>
          </Col>
        )}
      </Row>

      <FormGroup>
        <Label>Creator *</Label>
        <CustomInput
          required
          placeholder="Creator"
          value={props.creator}
          onChange={props.CreatorChange}
        />
      </FormGroup>

      <hr />
      <h3 className="t-align-c">Parameters</h3>
      <br />
      <Alert color="primary">
        <div>Click on parameter to edit</div>
        <div>Click on header to reset inputs</div>
      </Alert>

      <Table>
        <thead className="parameters-table-header" onClick={ClearInputsClick}>
          <tr>
            <th>Key</th>
            <th>Data-Type</th>
            <th>Required</th>
            <th></th>
          </tr>
        </thead>
        <tbody className="parameters-table-body">
          {props.parameters.map((param, index) => (
            <tr
              key={index}
              onClick={(e) => onCopyParamClick(e, index, param)}
              className="parameters-table-row"
            >
              <td>{param.key}</td>
              <td>{param.dataType}</td>
              <td>{param.isRequired?.toString()}</td>
              <td className="t-align-r">
                <CloseButton onClick={(e) => onRemoveParamClick(e, index)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <br />

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Key *</Label>
            <CustomInput
              disabled={props.moduleId ? true : false}
              placeholder="Key"
              value={inputs.key}
              onChange={(e) => onChangeHandler("key", e.target.value)}
            />
            <FormText>Name of the variable that will hold the value.</FormText>
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Data-Type *</Label>
            <Select
              isDisabled={props.moduleId ? true : false}
              components={animatedComponents}
              closeMenuOnSelect={true}
              value={
                inputs.dataType
                  ? { value: inputs.dataType, label: inputs.dataType }
                  : ""
              }
              onChange={(e) => onChangeHandler("dataType", e)}
              options={[
                { value: "string", label: "string" },
                { value: "array", label: "array" },
              ]}
              className="custom-input"
            />
            <FormText>
              Single data-type (string), or a list data-type (array of strings).
            </FormText>
          </FormGroup>
        </Col>

        {inputs.dataType === "array" && (
          <>
            <Col md={6}>
              <FormGroup>
                <Label>Array Items Data-Type *</Label>
                <Select
                  isDisabled={props.moduleId ? true : false}
                  components={animatedComponents}
                  closeMenuOnSelect={true}
                  value={
                    inputs.arrayItemsDataType
                      ? {
                          value: inputs.arrayItemsDataType,
                          label: inputs.arrayItemsDataType,
                        }
                      : ""
                  }
                  onChange={(e) => onChangeHandler("arrayItemsDataType", e)}
                  options={[{ value: "string", label: "string" }]}
                  className="custom-input"
                />
                <FormText>Data type of all items within the list.</FormText>
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup switch>
                <br />
                <Label>Keep Duplicates</Label>
                <CustomInput
                  disabled={props.moduleId ? true : false}
                  type="switch"
                  checked={inputs.rawArray}
                  onChange={(e) =>
                    onChangeHandler("rawArray", e.target.checked)
                  }
                />
                <br />
                <FormText>
                  Keep the raw array with duplicates and single-space values.
                </FormText>
              </FormGroup>
              <br />
            </Col>
          </>
        )}

        <Col md={6}>
          <FormGroup switch>
            <br />
            <Label>Required</Label>
            <CustomInput
              disabled={props.moduleId ? true : false}
              type="switch"
              checked={inputs.isRequired}
              onChange={(e) => onChangeHandler("isRequired", e.target.checked)}
            />
            <br />
            <FormText>
              Variable either has to be required to provide a default value.
            </FormText>
          </FormGroup>
          <br />
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Default</Label>
            <CustomInput
              disabled={props.moduleId ? true : false}
              placeholder="Default"
              value={inputs.default}
              onChange={(e) => onChangeHandler("default", e.target.value)}
            />
            <FormText>
              Variable must provide a default value or be required on input.
            </FormText>
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup switch>
            <br />
            <Label>No Whitespaces</Label>
            <CustomInput
              disabled={props.moduleId ? true : false}
              type="switch"
              checked={inputs.superTrim}
              onChange={(e) => onChangeHandler("superTrim", e.target.checked)}
            />
            <br />
            <FormText>Trim the extra whitespaces.</FormText>
          </FormGroup>
          <br />
        </Col>

        <Col md={6}>
          <FormGroup switch>
            <br />
            <Label>No Spaces</Label>
            <CustomInput
              disabled={props.moduleId ? true : false}
              type="switch"
              checked={inputs.megaTrim}
              onChange={(e) => onChangeHandler("megaTrim", e.target.checked)}
            />
            <br />
            <FormText>Remove all spaces.</FormText>
          </FormGroup>
          <br />
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Allowed Values</Label>
            <CustomInput
              type="textarea"
              rows={3}
              placeholder={`Apples\nBananas\nPeaches`}
              value={
                inputs.allowedValues
                  ? inputs.allowedValues.join("\n")
                  : undefined
              }
              onChange={(e) => onChangeHandler("allowedValues", e.target.value)}
            />
            <FormText>List of allowed values.</FormText>
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>Disallowed Values</Label>
            <CustomInput
              type="textarea"
              rows={3}
              placeholder={`Tomatoes\nOnions\nGarlics`}
              value={
                inputs.disAllowedValues
                  ? inputs.disAllowedValues.join("\n")
                  : undefined
              }
              onChange={(e) =>
                onChangeHandler("disAllowedValues", e.target.value)
              }
            />
            <FormText>List of denied values.</FormText>
          </FormGroup>
        </Col>

        <Col md={12}>
          <FormGroup>
            <Label>Label</Label>
            <CustomInput
              placeholder="Label"
              value={inputs.label}
              onChange={(e) => onChangeHandler("label", e.target.value)}
            />
            <FormText>Label used for the Config page.</FormText>
          </FormGroup>
        </Col>
      </Row>
      {!props.moduleId && (
        <>
          <br />
          <Row>
            <Col md={paramIndex || paramIndex === 0 ? 6 : 12}>
              <div onClick={onAddParamClick} className="text-button t-align-c">
                <FiIcons.FiPlus /> Add Parameter
              </div>
            </Col>

            {(paramIndex || paramIndex === 0) && (
              <Col md={6}>
                <div
                  onClick={onUpdateParamClick}
                  className="text-button t-align-c"
                >
                  <FiIcons.FiUpload /> Update Parameter
                </div>
              </Col>
            )}
          </Row>
        </>
      )}

      <hr />
      <h3 className="t-align-c">Jinja2 Template</h3>
      <br />
      <FormGroup>
        <InputGroup>
          <div className="syntax-highlight-linecount">
            {props?.template
              ?.split("\n")
              ?.map((_, i) => <div key={i}>{i + 1}</div>) || 1}
          </div>
          <Input
            className="custom-input syntax-highlight ws-no-wrap"
            type="textarea"
            spellCheck="false"
            value={props.template}
            onChange={props.TemplateChange}
            rows={props?.template?.split("\n")?.length || 1}
          />
        </InputGroup>
      </FormGroup>

      <FormGroup>
        <Button type="submit" color="primary">
          Save
        </Button>
      </FormGroup>
    </>
  );
};

export default ModulesFormInputs;
