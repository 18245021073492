///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Form, Spinner } from '@ibiliaze/reactstrap';
import countryList from 'react-select-country-list';
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import SiteContext from '../../context/ObjectContext';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postSite, putSite } from '../../actions/sites';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import SitesFormInputs from './SitesFormInputs';
import { SiteHowto } from '../layout/Howto';
//////////////////////////////////////////////////////////////////////////////////////////

const SitesForm = ({ auth, postSite, putSite }) => {
  // State
  const [siteId, setSiteId] = useState('');
  // For inputs
  const [siteName, setSiteName] = useState('');
  const [description, setDescription] = useState('');
  const [country, setCountry] = useState('');
  const [department, setDepartment] = useState('');
  const [type, setType] = useState('');
  const [floor, setFloor] = useState('');

  // Helper functions
  const countryOptions = useMemo(() => countryList().getData(), []);

  // History
  const navigate = useNavigate();

  // onChange functions
  const onSiteNameChange = e => setSiteName(e.target.value);
  const onDescriptionChange = e => setDescription(e.target.value);
  const onCountryChange = e => setCountry(e.label); // from react-select
  const onDepartmentChange = e => setDepartment(e.target.value);
  const onTypeChange = e => setType(e.target.value);
  const onFloorChange = e => setFloor(e.target.value);

  // Lifecycle hooks
  useEffect(() => {
    const edit = window.location.pathname.replace('/sites/edit', '');
    !!edit && setSiteId(edit.replace('/', ''));

    const req = async () => {
      try {
        if (!!edit) {
          const response = await apiRequest({ endpoint: 'site', method: 'GET', params: edit });
          if (response.isError) throw new Error(response.message);
          setSiteName(response?.siteName);
          setDescription(response?.description);
          setCountry(response?.country);
          setDepartment(response?.department);
          setType(response?.type);
          setFloor(response?.floor);
        }
      } catch (e) {
        setSiteId('');
      }
    };
    req();
  }, []);

  // onSubmit function
  const onSubmit = async e => {
    e.preventDefault();

    if (!auth.isAuthenticated) return navigate.push('/auth');

    const req = siteId ? putSite : postSite;
    await req({
      siteId: siteId ? siteId : undefined,
      siteName: siteId ? undefined : siteName,
      description,
      country: country ? country : undefined,
      department,
      type,
      floor,
    });
  };

  // JSX
  return (
    <SiteContext.Provider
      value={{
        countryOptions,
        siteId,
        siteName,
        description,
        country,
        department,
        type,
        floor,
        onSiteNameChange,
        onDescriptionChange,
        onCountryChange,
        onDepartmentChange,
        onTypeChange,
        onFloorChange,
      }}
    >
      <div className='below-header'>
        <h1 className='t-align-c no-m'>Sites</h1>
        <section className='page p-b-m p-t-m' style={{ minHeight: '100vh' }}>
          <Form onSubmit={onSubmit}>
            {siteId && !siteName ? <Spinner color='primary' className='object-list__spnr' /> : <SitesFormInputs />}
          </Form>
          <SiteHowto />
        </section>
      </div>
    </SiteContext.Provider>
  );
};

const mapStateToProps = state => ({ message: state.message, auth: state.auth });

export default connect(mapStateToProps, { postSite, putSite })(SitesForm);
