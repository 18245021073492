///////////////////////////////////////////////////////////////////////////////////MODULES
import { useContext } from 'react';
import { FormGroup, CustomInput, Label, Button } from '@ibiliaze/reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import GroupContext from '../../context/ObjectContext';
//////////////////////////////////////////////////////////////////////////////////////////

const animatedComponents = makeAnimated();

const GroupsFormInputs = () => {
  // Context
  const props = useContext(GroupContext);

  // JSX
  return (
    <>
      <FormGroup>
        <Label>Group Name *</Label>
        <CustomInput
          autoFocus
          required
          disabled={props.groupId ? true : false}
          type='text'
          placeholder='Group Name'
          value={props.groupName}
          onChange={props.onGroupNameChange}
          bsSize='sm'
        />
      </FormGroup>

      <FormGroup>
        <Label>Description</Label>
        <CustomInput
          type='textarea'
          placeholder='Description'
          rows={props?.description?.split('\n')?.length || 1}
          value={props.description}
          onChange={props.onDescriptionChange}
          bsSize='sm'
        />
      </FormGroup>

      <FormGroup>
        <Label>Assigned Configs</Label>
        <Select
          components={animatedComponents}
          closeMenuOnSelect={false}
          isSearchable
          isMulti
          value={props.assignedConfigs}
          onChange={props.onAssignedConfigsChange}
          options={props.configs.map(config => ({ value: config, label: config }))}
          className='custom-input'
        />
      </FormGroup>

      <hr />
      <FormGroup>
        <Button type='submit' color='primary'>
          Save
        </Button>
      </FormGroup>
    </>
  );
};

export default GroupsFormInputs;
