///////////////////////////////////////////////////////////////////////////////////MODULES
import { useContext } from 'react';
import { FormGroup, CustomInput, Label, Row, Col, FormText, Button, Input, InputGroup } from '@ibiliaze/reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import NodeContext from '../../context/ObjectContext';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { allowedPlatforms } from '../../utils/platformParser';
//////////////////////////////////////////////////////////////////////////////////////////

const animatedComponents = makeAnimated();

const NodesFormInputs = () => {
  // Context
  const props = useContext(NodeContext);

  // JSX
  return (
    <>
      <FormGroup>
        <Label>Node Name *</Label>
        <CustomInput
          autoFocus
          required
          disabled={props.nodeId ? true : false}
          type='text'
          placeholder='Node Name'
          value={props.nodeName}
          onChange={props.onNodeNameChange}
        />
      </FormGroup>

      <FormGroup>
        <Label>Description</Label>
        <CustomInput
          type='textarea'
          placeholder='Description'
          rows={props?.description?.split('\n')?.length || 1}
          value={props.description}
          onChange={props.onDescriptionChange}
        />
      </FormGroup>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Assigned Configs</Label>
            <Select
              components={animatedComponents}
              closeMenuOnSelect={false}
              isSearchable
              isMulti
              value={props.assignedConfigs}
              onChange={props.onAssignedConfigsChange}
              options={props.configs.map(config => ({ value: config, label: config }))}
              className='custom-input'
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label>Groups</Label>
            <Select
              components={animatedComponents}
              closeMenuOnSelect={false}
              isSearchable
              isMulti
              value={props.groups}
              onChange={props.onGroupsChange}
              options={props.groupsState.map(groupStateItem => ({
                value: groupStateItem,
                label: groupStateItem,
              }))}
              className='custom-input'
            />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label>Site Name *</Label>
            <Select
              required
              components={animatedComponents}
              closeMenuOnSelect={true}
              isSearchable
              value={props.siteName ? { value: props.siteName, label: props.siteName } : ''}
              onChange={props.onSiteNameChange}
              options={props.sites.map(site => ({ value: site, label: site }))}
              className='custom-input'
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label>Type</Label>
        <CustomInput type='text' placeholder='Master Node' value={props.type} onChange={props.onTypeChange} />
      </FormGroup>

      <FormGroup>
        <Label>Platform *</Label>
        <Select
          required
          components={animatedComponents}
          closeMenuOnSelect={true}
          isSearchable
          value={props.platform ? { value: props.platform, label: props.platform } : ''}
          onChange={props.onPlatformChange}
          options={allowedPlatforms.map(plt => ({ value: plt, label: plt }))}
          className='custom-input'
        />
      </FormGroup>

      <FormGroup>
        <Label>Layer</Label>
        <Select
          components={animatedComponents}
          closeMenuOnSelect={true}
          isSearchable
          value={props.layer ? { value: props.layer, label: props.layer } : ''}
          onChange={props.onLayerChange}
          options={[
            { value: 'Access', label: 'Access' },
            { value: 'Distribution', label: 'Distribution' },
            { value: 'Core', label: 'Core' },
            { value: 'Other', label: 'Other' },
          ]}
          className='custom-input'
        />
      </FormGroup>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label>Host *</Label>
            <CustomInput
              required
              type='text'
              placeholder='r1.mydomain.com'
              value={props.host}
              onChange={props.onHostChange}
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>SSH Username *</Label>
            <CustomInput
              required
              type='text'
              placeholder='admin'
              value={props.sshUsername}
              onChange={props.onSshUsernameChange}
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>SSH Password *</Label>
            <CustomInput
              required
              type='password'
              placeholder='mypassword'
              value={props.sshPassword}
              onChange={props.onSshPasswordChange}
            />
          </FormGroup>
        </Col>

        <Col md={6}>
          <FormGroup>
            <Label>SSH Port *</Label>
            <CustomInput
              required
              type='number'
              placeholder='22'
              value={props.sshPort}
              onChange={props.onSshPortChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label>Tags</Label>
        <InputGroup>
          <div className='syntax-highlight-linecount'>
            {props?.tags?.split('\n')?.map((_, i) => <div key={i}>{i + 1}</div>) || 1}
          </div>
          <Input
            className='custom-input syntax-highlight ws-no-wrap'
            type='textarea'
            spellCheck='false'
            rows={props?.tags?.split('\n')?.length || 3}
            placeholder='key1=value1 OR key1=value1,value2'
            value={props.tags}
            onChange={props.onTagsChange}
          />
        </InputGroup>
        <FormText>
          {
            'Tags can be used to select specific nodes when running configs. To use a tag, add {{node.tags.<key>}} to the module template. You can specify the node parameters, e.g nodeName as: {{node.nodeName}}'
          }
        </FormText>
      </FormGroup>

      <hr />
      <FormGroup>
        <Button type='submit' color='primary'>
          Save
        </Button>
      </FormGroup>
    </>
  );
};

export default NodesFormInputs;
