///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import {
  Row,
  Col,
  FormGroup,
  Label,
  Spinner,
  InputGroup,
  CustomButton,
  UncontrolledPopover,
  PopoverBody,
  CustomInput,
} from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
/////////////////////////////////////////////////////////////////////////////////////HOOKS
import { useGetItems } from '../../hooks/history';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Item from '../history/Item';
import Paging from '../layout/Paging';
import { HistoryHowto } from '../layout/Howto';
//////////////////////////////////////////////////////////////////////////////////////////

const HistoryPage = () => {
  // State
  const [search, setSearch] = useState(false);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [inputs, setInputs] = useState({ itemName: '', createdAt: 'createdAt:desc', task: 'all' });
  const [page, setPage] = useState(1);
  const [items] = useGetItems(inputs, page, search, setLoadSpinner);

  // onClick functions
  const onPageClick = index => (index < 1 ? setPage(1) : setPage(index));
  const onClearHistoryClick = async _ => await apiRequest({ endpoint: 'history', method: 'DELETE', params: '/all' });

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.label ? e.value : e.target.value }));

  // JSX
  return (
    <div className='below-header'>
      <h1 className='t-align-c no-m'>History</h1>
      <section className='page p-b-m p-t-m' style={{ minHeight: '100vh' }}>
        <FormGroup>
          <CustomButton onClick={onClearHistoryClick} color='danger' id='clear-history'>
            Clear history
          </CustomButton>
          <UncontrolledPopover trigger='focus' placement='right' target='clear-history' className='info-button-popover'>
            <PopoverBody>History marked for deletion!</PopoverBody>
          </UncontrolledPopover>
        </FormGroup>
        <hr />
        <Row>
          <Col md={3}>
            <FormGroup>
              <Label>Filter</Label>
              <CustomInput type='select' name='task' value={inputs.task} onChange={onInputsChange}>
                <option>config</option>
                <option>ping</option>
                <option>build</option>
                <option>all</option>
              </CustomInput>
            </FormGroup>
          </Col>
          <Col md={3}>
            <FormGroup>
              <Label>Sort</Label>
              <CustomInput type='select' name='sortBy' value={inputs.sortBy} onChange={onInputsChange}>
                <option>createdAt:asc</option>
                <option>createdAt:desc</option>
                <option>updatedAt:asc</option>
                <option>updatedAt:desc</option>
              </CustomInput>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Search</Label>
              <InputGroup>
                <CustomInput
                  type='text'
                  placeholder='Config Name'
                  value={inputs.itemName}
                  name='itemName'
                  onChange={onInputsChange}
                />
                <CustomButton onClick={e => setSearch(!search)} color='secondary'>
                  Search
                </CustomButton>
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>

        <Paging page={page} onPageClick={onPageClick} />
        {loadSpinner ? (
          <Spinner color='primary' className='object-list__spnr' />
        ) : items?.length !== 0 ? (
          items.map(item => <Item key={item._id} item={item} />)
        ) : (
          <HistoryHowto />
        )}
      </section>
    </div>
  );
};

export default HistoryPage;
