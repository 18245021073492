////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { OrgHowto } from '../../layout/Howto';
//////////////////////////////////////////////////////////////////////////////////////////

const SuccessPaymentPage = () => (
  <div className='below-header'>
    <h1 className='t-align-c no-m'>Payment Successful</h1>
    <section className='page p-b-m p-t-m' style={{ minHeight: '100vh' }}>
      <OrgHowto />
    </section>
  </div>
);

export default SuccessPaymentPage;
