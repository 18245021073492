///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Form,
  FormGroup,
  Label,
  CustomButton,
  UncontrolledTooltip,
  CustomInput,
  InputGroup,
  FormText,
  TextButton,
} from "@ibiliaze/reactstrap";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { login, register, getOrg, putOrgPassword } from "../../actions/auth";
import { setAlert } from "../../actions/alert";
import { confirmEmail } from "../../actions/email";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import SubscriptionPortal from "../layout/SubscriptionPortal";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import superTrim from "../../utils/superTrim";
//////////////////////////////////////////////////////////////////////////////////////////

const AuthPage = ({
  isAuthenticated,
  setAlert,
  login,
  register,
  getOrg,
  putOrgPassword,
  confirmEmail,
}) => {
  // State
  const [product, setProduct] = useState({});
  const [authType, setAuthType] = useState("Login");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [orgName, setOrgName] = useState("");
  const [domain, setDomain] = useState("");
  const [password2, setPassword2] = useState("");
  const [code, setCode] = useState("");

  // History
  const navigate = useNavigate();

  // onChange functions
  const onEmailChange = (e) => setEmail(superTrim(e.target.value));
  const onUsernameChange = (e) => setUsername(superTrim(e.target.value));
  const onPasswordChange = (e) => setPassword(e.target.value);
  const onOrgNameChange = (e) => setOrgName(e.target.value);
  const onDomainChange = (e) => setDomain(e.target.value);
  const onPassword2Change = (e) => setPassword2(e.target.value);
  const onCodeChange = (e) => setCode(e.target.value);

  // onClick functions
  const onSendConfirmationClick = async (_) =>
    await confirmEmail({ email: email?.toLowerCase() });

  // Lifecycle hooks
  useEffect(() => {
    // If authentication is succesful redirect to the home page and set the current object states
    const req = async (_) => {
      try {
        if (isAuthenticated) {
          navigate("/");
          await getOrg();
        }
      } catch (e) {
        console.log(e);
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    if (authType !== "Login" && password !== password2) {
      setAlert("Passwords do not match", true, null, null);
    } else {
      const req =
        authType === "Register"
          ? register
          : authType === "Reset Password"
          ? putOrgPassword
          : login;
      await req({
        email,
        username,
        password,
        orgName,
        domain,
        code,
        subscriptionType: product?.serviceName,
        items: [
          {
            id: product?.id,
            quantity: 1,
          },
        ],
      });
    }
  };

  // JSX
  return (
    <div className="below-header">
      <h1 className="t-align-c no-m">{authType}</h1>
      <section className="page p-b-m p-t-m" style={{ minHeight: "100vh" }}>
        {authType === "Register" && (
          <>
            <h2 className="t-align-c">Subscribe to a Plan</h2>
            <br />
            <SubscriptionPortal
              setAlert={setAlert}
              onProductClick={setProduct}
            />
          </>
        )}

        {!!product && (
          <>
            <h3>{product.serviceName}</h3>
            <br />
          </>
        )}

        <Form onSubmit={onSubmit}>
          <FormGroup>
            <Label>Email *</Label>
            <InputGroup>
              <CustomInput
                required
                autoFocus
                type="email"
                name="email"
                placeholder="user@example.com"
                value={email}
                onChange={onEmailChange}
              />
              {authType !== "Login" && (
                <>
                  <CustomButton
                    onClick={onSendConfirmationClick}
                    color="success"
                  >
                    Send Verification
                  </CustomButton>
                </>
              )}
            </InputGroup>
          </FormGroup>

          {authType !== "Login" && (
            <FormGroup>
              <Label>One Time Passcode *</Label>
              <CustomInput
                required
                name="code"
                placeholder="One Time Passcode"
                value={code}
                onChange={onCodeChange}
              />
              <FormText>
                Your One Time Passcode (OTP) can only be used once
              </FormText>
            </FormGroup>
          )}

          {authType !== "Reset Password" && (
            <FormGroup>
              <Label>Username *</Label>
              <CustomInput
                required
                type="text"
                placeholder="Username"
                value={username}
                onChange={onUsernameChange}
              />
            </FormGroup>
          )}

          {authType === "Register" && (
            <>
              <FormGroup>
                <Label>Organisation Name *</Label>
                <CustomInput
                  required
                  type="text"
                  placeholder="My Corp"
                  value={orgName}
                  onChange={onOrgNameChange}
                />
              </FormGroup>

              <FormGroup>
                <Label>Domain *</Label>
                <CustomInput
                  required
                  type="text"
                  placeholder="mydomain.com"
                  value={domain}
                  onChange={onDomainChange}
                />
              </FormGroup>
            </>
          )}

          <FormGroup>
            <Label>Password *</Label>
            <CustomInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={onPasswordChange}
            />
          </FormGroup>

          {authType !== "Login" && (
            <FormGroup>
              <Label>Retype password *</Label>
              <CustomInput
                required
                type="password"
                placeholder="Password"
                value={password2}
                onChange={onPassword2Change}
              />
            </FormGroup>
          )}

          <span className="form-label">
            {authType === "Login"
              ? "Don't have an account? "
              : "Already have an account? "}
          </span>
          <TextButton
            onClick={(_) =>
              setAuthType(authType !== "Login" ? "Login" : "Register")
            }
          >
            {authType !== "Login" ? "Login" : "Register"}
          </TextButton>

          <div>
            <span className="form-label">Forgot your password? </span>
            <TextButton onClick={(_) => setAuthType("Reset Password")}>
              Reset your password
            </TextButton>
          </div>

          <br />
          <FormGroup>
            <CustomButton type="submit" color="primary" id="save-button">
              {authType}
            </CustomButton>
          </FormGroup>
          <UncontrolledTooltip placement="top" target="save-button">
            {authType}
          </UncontrolledTooltip>
        </Form>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, {
  login,
  register,
  getOrg,
  setAlert,
  putOrgPassword,
  confirmEmail,
})(AuthPage);
