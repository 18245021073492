///////////////////////////////////////////////////////////////////////////////////MODULES
import React from 'react';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import {
  FiPlay as Play,
  FiCopy as Copy,
  FiUploadCloud as UploadCloud,
  FiTrash as Trash,
  FiChevronDown as ChevronDown,
  FiChevronUp as ChevronUp,
  FiEdit as Edit,
  FiAlertCircle as AlertCircle,
  FiCheck as Check,
  FiUpload as Upload,
  FiUsers as Users,
  FiCodesandbox as Codesandbox,
  FiPlus as Plus,
  FiDownload as Download,
  FiCrosshair as Crosshair,
  FiStar as Star,
  FiExternalLink as ExternalLink,
} from 'react-icons/fi';
//////////////////////////////////////////////////////////////////////////////////////////

export const FiPlay = props => <Play {...props} />;
export const FiCopy = props => <Copy {...props} />;
export const FiUploadCloud = props => <UploadCloud {...props} />;
export const FiTrash = props => <Trash {...props} />;
export const FiChevronDown = props => <ChevronDown {...props} />;
export const FiChevronUp = props => <ChevronUp {...props} />;
export const FiEdit = props => <Edit {...props} />;
export const FiAlertCircle = props => <AlertCircle {...props} />;
export const FiCheck = props => <Check {...props} />;
export const FiUpload = props => <Upload {...props} />;
export const FiUsers = props => <Users {...props} />;
export const FiCodesandbox = props => <Codesandbox {...props} />;
export const FiPlus = props => <Plus {...props} />;
export const FiDownload = props => <Download {...props} />;
export const FiCrosshair = props => <Crosshair {...props} />;
export const FiStar = props => <Star {...props} />;
export const FiExternalLink = props => <ExternalLink {...props} />;
