const ReturnPolicyPage = () => (
  <section className='below-header p-b-m page' style={{ minHeight: '100vh' }}>
    Last updated January 15, 2023
    <h1>REFUNDS</h1>
    <p>50% refund will be issued within 30 days of transaction date.</p>
    <h3>QUESTIONS</h3>
    <p>If you have any questions concerning our return policy, please contact us at:</p>
    nodeconfig1@gmail.com
    <p>This return policy was created using Termly's Return and Refund Policy Generator.</p>
  </section>
);

export default ReturnPolicyPage;
