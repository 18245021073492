///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/admin/settings
export const getSettingsSuccess = settings => ({ type: types.GET_SETTINGS_SUCCESS, settings });
export const getSettingsFailure = errorMessage => ({ type: types.GET_SETTINGS_FAILURE, errorMessage });
export const getSettings = endpoint => async dispatch => {
  try {
    const res = await api.get(
      !endpoint ? `${resolveHost()}/api/admin/settings` : `${resolveHost()}/api/admin/settings${endpoint}`
    );

    dispatch(getSettingsSuccess(res.data.settings));
  } catch (e) {
    dispatch(getSettingsFailure(e.message));
  }
};

// GET /api/admin/object
export const getObject = endpoint => async dispatch => {
  try {
    dispatch(setAlert('Fetching object...', false, null, null, true));
    const res = await api.get(`${resolveHost()}/api/admin/object${endpoint}`);

    dispatch(setAlert(res.data.message, false, res.status, null));
    return res.data.object;
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, null));
  }
};

// GET /api/admin/org
export const getOrg = endpoint => async dispatch => {
  try {
    dispatch(setAlert('Fetching account...', false, null, null, true));
    const res = await api.get(`${resolveHost()}/api/admin/org${endpoint}`);

    dispatch(setAlert(res.data.message, false, res.status, null));
    return res.data.object;
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, null));
  }
};

// POST /api/admin/org
export const postOrg = data => async dispatch => {
  try {
    dispatch(setAlert('Creating object...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/admin/org`, data);

    dispatch(setAlert(res.data.message, false, res.status, null));
    return res.data.object;
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, null));
  }
};

// PUT /api/admin/settings
export const putSettingsSuccess = settings => ({ type: types.PUT_SETTINGS_SUCCESS, settings });
export const putSettingsFailure = errorMessage => ({ type: types.PUT_SETTINGS_FAILURE, errorMessage });
export const putSettings = data => async dispatch => {
  try {
    dispatch(setAlert('Saving settings...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/admin/settings`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_SETTINGS_SUCCESS));
    dispatch(putSettingsSuccess(res.data.settings));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_SETTINGS_FAILURE));
    dispatch(putSettingsFailure(e.message));
  }
};

// DELETE /api/admin/object
export const deleteObject = objectId => async dispatch => {
  try {
    dispatch(setAlert('Deleting object...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/admin/object${objectId}`);

    dispatch(setAlert(res.data.message, false, res.status, null));
    return res.data.object;
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, null));
  }
};
