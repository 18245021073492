///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  CustomButton,
  Alert,
  UncontrolledTooltip,
  Spinner,
  UncontrolledAccordion,
  AccordionItem,
} from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from '../../utils/icons';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { copyToClipboard } from '../../utils/copyToClipboard';
import apiRequest, { download } from '../../utils/httpRequest';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Node from '../history/Node';
import { HistoryHowto } from '../layout/Howto';
//////////////////////////////////////////////////////////////////////////////////////////

const ResultsPage = () => {
  // State
  const [item, setItem] = useState(null);
  const [loadSpinner, setLoadSpinner] = useState(false);
  const [clientMessage, setClientMessage] = useState({ text: '', loading: false, color: 'secondary' });

  // History
  const navigate = useNavigate();

  // onClick functions
  const onNodeClick = nodeId => navigate(`/nodes/edit/${nodeId}`);
  const onCopyClick = _ => {
    copyToClipboard(item?.payload?.response?.map(node => node.result).join('\n'));
    setClientMessage(_ => ({ text: 'Copied to clipboard', loading: false, color: 'success' }));
  };
  const onDownloadClick = _ => download(item?._id, item?.payload?.response?.map(node => node.result).join('\n'));

  // Lifecycle hooks
  useEffect(() => {
    const req = async () => {
      try {
        setLoadSpinner(true);
        const edit = window.location.pathname.replace('/results', '');
        if (!!edit) {
          const response = await apiRequest({ endpoint: 'history', method: 'GET', params: edit });
          if (response.isError) throw new Error(response.message);
          setItem(response);
        }
        setLoadSpinner(false);
      } catch (e) {
        setLoadSpinner(false);
      }
    };
    req();
  }, []);

  // JSX
  return (
    <div className='below-header'>
      <h1 className='t-align-c no-m'>Result</h1>
      <section className='page p-b-m p-t-m' style={{ minHeight: '100vh' }}>
        <hr />
        <h3 className='t-align-c text-button' onClick={_ => navigate(`/configs/edit/${item?.objectId}`)}>
          {item?.itemName}
        </h3>
        <div className='t-align-c list-item__p1'>{item?._id}</div>
        <div className='t-align-c list-item__p1'>
          {!!item?.payload?.isError ? <FiIcons.FiAlertCircle /> : <FiIcons.FiCheck />}{' '}
          <span className='list-item__p1'>{item?.createdAt}</span>
        </div>
        <div className='t-align-c list-item__p1'>Node count: {item?.payload?.response?.length}</div>
        <div className='t-align-c'>
          <CustomButton outline onClick={onDownloadClick} color='success' id={`download-${item?._id}`}>
            <FiIcons.FiDownload />
          </CustomButton>
          <UncontrolledTooltip placement='bottom' target={`download-${item?._id}`}>
            Download
          </UncontrolledTooltip>
          <CustomButton outline color='primary' onClick={onCopyClick} id={`copy-${item?._id}`}>
            <FiIcons.FiCopy />
          </CustomButton>
          <UncontrolledTooltip placement='bottom' target={`copy-${item?._id}`}>
            Copy
          </UncontrolledTooltip>
        </div>
        {clientMessage.text && (
          <>
            <br />
            <Alert color={clientMessage.color}>{clientMessage.text}</Alert>
          </>
        )}

        <br />
        <UncontrolledAccordion stayOpen>
          {loadSpinner ? (
            <Spinner color='primary' className='object-list__spnr' />
          ) : !!item ? (
            item?.payload?.response?.map((node, index) => (
              <AccordionItem md={6} key={index}>
                <Node node={node} itemId={item?._id} onNodeClick={onNodeClick} />
              </AccordionItem>
            ))
          ) : (
            <HistoryHowto />
          )}
        </UncontrolledAccordion>
      </section>
    </div>
  );
};

export default ResultsPage;
