///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormGroup, Row, Col, Label, CustomInput } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const SiteListItem = ({ site }) => (
  <Row>
    <Col md={4}>
      <FormGroup>
        <Label>Site Name</Label>
        <CustomInput disabled type='text' placeholder='Site Name' value={site && site.siteName} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Label>Country</Label>
        <CustomInput disabled type='text' placeholder='Country' value={site && site.country} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Label>Department</Label>
        <CustomInput disabled type='text' placeholder='Department' value={site && site.department} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={6}>
      <FormGroup>
        <Label>Type</Label>
        <CustomInput disabled type='text' placeholder='Type' value={site && site.type} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={6}>
      <FormGroup>
        <Label>Floor</Label>
        <CustomInput disabled type='text' placeholder='Floor' value={site && site.floor} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={12}>
      <FormGroup>
        <Label>Description</Label>
        <CustomInput
          disabled
          type='textarea'
          rows={site?.description?.split('\n')?.length || 1}
          placeholder='Description'
          value={site && site.description}
          onChange={e => {}}
        />
      </FormGroup>
    </Col>
  </Row>
);

export default SiteListItem;
