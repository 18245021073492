///////////////////////////////////////////////////////////////////////////////////MODULES
import { FormGroup, Row, Col, Label, CustomInput } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const NodeListItem = ({ node }) => (
  <Row>
    <Col md={4}>
      <FormGroup>
        <Label>Node Name</Label>
        <CustomInput disabled type='text' placeholder='Node Name' value={node && node.nodeName} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Label>Platform</Label>
        <CustomInput disabled type='text' placeholder='Platform' value={node && node.platform} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Label>Host</Label>
        <CustomInput disabled type='text' placeholder='Host' value={node && node.host} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Label>Site Name</Label>
        <CustomInput disabled type='text' placeholder='Site Name' value={node && node.siteName} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Label>SSH Username</Label>
        <CustomInput
          disabled
          type='text'
          placeholder='SSH Username'
          value={node && node.sshUsername}
          onChange={e => {}}
        />
      </FormGroup>
    </Col>
    <Col md={4}>
      <FormGroup>
        <Label>SSH Port</Label>
        <CustomInput disabled type='text' placeholder='SSH Port' value={node && node.sshPort} onChange={e => {}} />
      </FormGroup>
    </Col>
    <Col md={12}>
      <FormGroup>
        <Label>Description</Label>
        <CustomInput
          disabled
          type='textarea'
          rows={node?.description?.split('\n')?.length || 1}
          placeholder='Description'
          value={node && node.description}
          onChange={e => {}}
        />
      </FormGroup>
    </Col>
  </Row>
);

export default NodeListItem;
