/////////////////////////////////////////////////////////////////////////////////////TYPES
import { GET_MESSAGES, CLEAR_MESSAGES } from './types/types';
//////////////////////////////////////////////////////////////////////////////////////////

export const setAlert = (text, isError, status, id = null, loading = false) => ({
  type: GET_MESSAGES,
  payload: { text, isError, status, id, loading },
});

export const clearMessages = () => ({
  type: CLEAR_MESSAGES,
});
