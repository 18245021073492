///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  FormGroup,
  Label,
  CustomButton,
  CustomInput,
  Row,
  Col,
  InputGroup,
  Input,
} from "@ibiliaze/reactstrap";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import {
  postOrg,
  deleteObject,
  getObject,
  getOrg,
  putSettings,
} from "../../actions/admin";
//////////////////////////////////////////////////////////////////////////////////////////

const AdminPage = ({
  auth,
  settings,
  postOrg,
  deleteObject,
  getObject,
  getOrg,
  putSettings,
}) => {
  // State
  const [bannerInputs, setBannerInputs] = useState({
    bannerHeader: "",
    bannerBody: "",
    bannerColour: "",
    bannerTextColour: "",
    showBanner: false,
  });
  const [orgInputs, setOrgInputs] = useState({
    email: "",
    username: "",
    password: "",
    orgName: "",
    domain: "",
    subscriptionType: "",
    orgId: "",
    orgEmail: "",
    orgOrgName: "",
  });
  const [siteInputs, setSiteInputs] = useState({ siteId: "" });
  const [groupInputs, setGroupInputs] = useState({ groupId: "" });
  const [nodeInputs, setNodeInputs] = useState({ nodeId: "" });
  const [moduleInputs, setModuleInputs] = useState({ moduleId: "" });
  const [configInputs, setConfigInputs] = useState({ configId: "" });
  const [selectedObject, setSelectedObject] = useState({
    objectType: null,
    object: null,
  });

  // History
  const navigate = useNavigate();

  // onChange functions
  const onBannerInputsChange = (e) =>
    setBannerInputs((c) => ({
      ...c,
      [e.target.name]: e.label ? e.value : e.target.value,
    }));
  const onOrgInputsChange = (e) =>
    setOrgInputs((c) => ({
      ...c,
      [e.target.name]: e.label ? e.value : e.target.value,
    }));
  const onSiteInputsChange = (e) =>
    setSiteInputs((c) => ({
      ...c,
      [e.target.name]: e.label ? e.value : e.target.value,
    }));
  const onNodeInputsChange = (e) =>
    setNodeInputs((c) => ({
      ...c,
      [e.target.name]: e.label ? e.value : e.target.value,
    }));
  const onGroupInputsChange = (e) =>
    setGroupInputs((c) => ({
      ...c,
      [e.target.name]: e.label ? e.value : e.target.value,
    }));
  const onModuleInputsChange = (e) =>
    setModuleInputs((c) => ({
      ...c,
      [e.target.name]: e.label ? e.value : e.target.value,
    }));
  const onConfigInputsChange = (e) =>
    setConfigInputs((c) => ({
      ...c,
      [e.target.name]: e.label ? e.value : e.target.value,
    }));

  // Lifecycle hooks
  useEffect(() => {
    if (!auth || !auth.isAuthenticated || !auth.org.admin) {
      navigate("/");
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    if (!!settings) {
      setBannerInputs({ ...settings });
    }
  }, [settings]);

  // onClick functions
  const onPostOrgClick = async (_) => {
    try {
      await postOrg({ ...orgInputs });
    } catch (e) {}
  };

  const onGetAccountByEmail = async (_) => {
    try {
      setSelectedObject({ objectType: null, object: null });
      const org = await getOrg(`?email=${orgInputs.orgEmail}`);
      setSelectedObject({
        objectType: "org",
        object: JSON.stringify(org, null, 2),
      });
    } catch (e) {}
  };

  const onGetAccountByOrgName = async (_) => {
    try {
      setSelectedObject({ objectType: null, object: null });
      const org = await getOrg(`?orgName=${orgInputs.orgOrgName}`);
      setSelectedObject({
        objectType: "org",
        object: JSON.stringify(org, null, 2),
      });
    } catch (e) {}
  };

  const onGetObjectClick = async (objectType) => {
    try {
      setSelectedObject({ objectType: null, object: null });
      const getObjectId = () => {
        switch (objectType) {
          case "org":
            return orgInputs.orgId;
          case "site":
            return siteInputs.siteId;
          case "group":
            return groupInputs.groupId;
          case "node":
            return nodeInputs.nodeId;
          case "module":
            return moduleInputs.moduleId;
          case "config":
            return configInputs.configId;
          default:
            return;
        }
      };

      const object = await getObject(
        `/${getObjectId()}?objectType=${objectType}`
      );
      setSelectedObject({
        objectType,
        object: JSON.stringify(object, null, 2),
      });
    } catch (e) {}
  };

  const onDeleteObjectClick = async (objectType) => {
    try {
      const getObjectId = () => {
        switch (objectType) {
          case "org":
            return orgInputs.orgId;
          case "site":
            return siteInputs.siteId;
          case "group":
            return groupInputs.groupId;
          case "node":
            return nodeInputs.nodeId;
          case "module":
            return moduleInputs.moduleId;
          case "config":
            return configInputs.configId;
          default:
            return;
        }
      };

      await deleteObject(`/${getObjectId()}?objectType=${objectType}`);
    } catch (e) {}
  };

  const onSaveBannerClick = async (_) => await putSettings({ ...bannerInputs });

  // Components
  const returnedObject = (objectType) =>
    selectedObject.objectType === objectType &&
    !!selectedObject.object &&
    typeof selectedObject.object === "string" && (
      <FormGroup>
        <InputGroup>
          <div className="syntax-highlight-linecount">
            {selectedObject.object
              .split("\n")
              ?.map((_, i) => <div key={i}>{i + 1}</div>) || 1}
          </div>
          <Input
            className="custom-input syntax-highlight ws-no-wrap"
            type="textarea"
            spellCheck="false"
            value={selectedObject.object}
            onChange={(_) => {}}
            rows={selectedObject.object?.split("\n")?.length || 1}
          />
        </InputGroup>
      </FormGroup>
    );

  // JSX
  return (
    <div className="below-header">
      <h1 className="t-align-c no-m">Admin</h1>
      <section className="page p-b-m p-t-m" style={{ minHeight: "100vh" }}>
        <h3 className="t-align-c">Banner</h3>

        <FormGroup>
          <Label>Header</Label>
          <CustomInput
            name="bannerHeader"
            placeholder="Announcement"
            value={bannerInputs.bannerHeader}
            onChange={onBannerInputsChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Body</Label>
          <CustomInput
            name="bannerBody"
            placeholder="This is our announcement"
            value={bannerInputs.bannerBody}
            onChange={onBannerInputsChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Colour</Label>
          <CustomInput
            name="bannerColour"
            placeholder="#FFF"
            value={bannerInputs.bannerColour}
            onChange={onBannerInputsChange}
          />
        </FormGroup>

        <FormGroup>
          <Label>Text Colour</Label>
          <CustomInput
            name="bannerTextColour"
            placeholder="#FFF"
            value={bannerInputs.bannerTextColour}
            onChange={onBannerInputsChange}
          />
        </FormGroup>

        <FormGroup>
          <CustomInput
            type="switch"
            checked={bannerInputs.showBanner}
            onChange={(e) =>
              setBannerInputs((c) => ({ ...c, showBanner: e.target.checked }))
            }
          />{" "}
          <Label>Show Banner</Label>
        </FormGroup>

        <CustomButton color="primary" onClick={onSaveBannerClick}>
          Save Banner
        </CustomButton>
        <hr />

        <h3 className="t-align-c no-m">Objects</h3>
        <Row>
          <Col md={6}>
            <h5 className="p-b-m p-t-l">Accounts</h5>
            <FormGroup>
              <Label>Email *</Label>
              <CustomInput
                required
                type="email"
                name="email"
                placeholder="user@example.com"
                value={orgInputs.email}
                onChange={onOrgInputsChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Username *</Label>
              <CustomInput
                required
                name="username"
                placeholder="Username"
                value={orgInputs.username}
                onChange={onOrgInputsChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Organisation Name *</Label>
              <CustomInput
                required
                name="orgName"
                placeholder="My Corp"
                value={orgInputs.orgName}
                onChange={onOrgInputsChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Domain *</Label>
              <CustomInput
                required
                name="domain"
                placeholder="mydomain.com"
                value={orgInputs.domain}
                onChange={onOrgInputsChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Subscription Type *</Label>
              <CustomInput
                required
                name="subscriptionType"
                placeholder="Starter"
                value={orgInputs.subscriptionType}
                onChange={onOrgInputsChange}
              />
            </FormGroup>

            <FormGroup>
              <Label>Password *</Label>
              <CustomInput
                type="password"
                name="password"
                placeholder="Password"
                value={orgInputs.password}
                onChange={onOrgInputsChange}
              />
            </FormGroup>

            <CustomButton onClick={onPostOrgClick} color="primary">
              Create Account
            </CustomButton>

            <hr />
            <FormGroup>
              <Label>Account ID</Label>
              <InputGroup>
                <CustomInput
                  name="orgId"
                  value={orgInputs.orgId}
                  onChange={onOrgInputsChange}
                  placeholder="650c32e149ab54f46198153e"
                />
                <CustomButton
                  color="primary"
                  onClick={async (_) => await onGetObjectClick("org")}
                >
                  Find
                </CustomButton>
                <CustomButton
                  color="danger"
                  onClick={async (_) => await onDeleteObjectClick("org")}
                >
                  Delete
                </CustomButton>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label>Email</Label>
              <InputGroup>
                <CustomInput
                  name="orgEmail"
                  type="email"
                  value={orgInputs.orgEmail}
                  onChange={onOrgInputsChange}
                  placeholder="foo@example.com"
                />
                <CustomButton color="primary" onClick={onGetAccountByEmail}>
                  Find
                </CustomButton>
              </InputGroup>
            </FormGroup>

            <FormGroup>
              <Label>Organisation Name</Label>
              <InputGroup>
                <CustomInput
                  name="orgOrgName"
                  value={orgInputs.orgOrgName}
                  onChange={onOrgInputsChange}
                  placeholder="NodeConfig"
                />
                <CustomButton color="primary" onClick={onGetAccountByOrgName}>
                  Find
                </CustomButton>
              </InputGroup>
            </FormGroup>

            {selectedObject.objectType === "org" &&
              !!selectedObject.object &&
              typeof selectedObject.object === "string" && (
                <FormGroup>
                  <InputGroup>
                    <div className="syntax-highlight-linecount">
                      {selectedObject.object
                        .split("\n")
                        ?.map((_, i) => <div key={i}>{i + 1}</div>) || 1}
                    </div>
                    <Input
                      className="custom-input syntax-highlight ws-no-wrap"
                      type="textarea"
                      spellCheck="false"
                      value={selectedObject.object}
                      onChange={(_) => {}}
                      rows={selectedObject.object?.split("\n")?.length || 1}
                    />
                  </InputGroup>
                </FormGroup>
              )}
          </Col>

          <Col md={6}>
            <h5 className="p-b-m p-t-l">Sites</h5>
            <FormGroup>
              <Label>Site ID</Label>
              <InputGroup>
                <CustomInput
                  name="siteId"
                  value={siteInputs.siteId}
                  onChange={onSiteInputsChange}
                  placeholder="650c32e149ab54f46198153e"
                />
                <CustomButton
                  color="primary"
                  onClick={async (_) => await onGetObjectClick("site")}
                >
                  Find
                </CustomButton>
                <CustomButton
                  color="danger"
                  onClick={async (_) => await onDeleteObjectClick("site")}
                >
                  Delete
                </CustomButton>
              </InputGroup>
            </FormGroup>

            {returnedObject("site")}
          </Col>

          <Col md={6}>
            <h5 className="p-b-m p-t-l">Groups</h5>
            <FormGroup>
              <Label>Group ID</Label>
              <InputGroup>
                <CustomInput
                  name="groupId"
                  value={groupInputs.groupId}
                  onChange={onGroupInputsChange}
                  placeholder="650c32e149ab54f46198153e"
                />
                <CustomButton
                  color="primary"
                  onClick={async (_) => await onGetObjectClick("group")}
                >
                  Find
                </CustomButton>
                <CustomButton
                  color="danger"
                  onClick={async (_) => await onDeleteObjectClick("group")}
                >
                  Delete
                </CustomButton>
              </InputGroup>
            </FormGroup>

            {returnedObject("group")}
          </Col>

          <Col md={6}>
            <h5 className="p-b-m p-t-l">Nodes</h5>
            <FormGroup>
              <Label>Node ID</Label>
              <InputGroup>
                <CustomInput
                  name="nodeId"
                  value={nodeInputs.nodeId}
                  onChange={onNodeInputsChange}
                  placeholder="650c32e149ab54f46198153e"
                />
                <CustomButton
                  color="primary"
                  onClick={async (_) => await onGetObjectClick("node")}
                >
                  Find
                </CustomButton>
                <CustomButton
                  color="danger"
                  onClick={async (_) => await onDeleteObjectClick("node")}
                >
                  Delete
                </CustomButton>
              </InputGroup>
            </FormGroup>

            {returnedObject("node")}
          </Col>

          <Col md={6}>
            <h5 className="p-b-m p-t-l">Modules</h5>
            <FormGroup>
              <Label>Module ID</Label>
              <InputGroup>
                <CustomInput
                  name="moduleId"
                  value={moduleInputs.moduleId}
                  onChange={onModuleInputsChange}
                  placeholder="650c32e149ab54f46198153e"
                />
                <CustomButton
                  color="primary"
                  onClick={async (_) => await onGetObjectClick("module")}
                >
                  Find
                </CustomButton>
                <CustomButton
                  color="danger"
                  onClick={async (_) => await onDeleteObjectClick("module")}
                >
                  Delete
                </CustomButton>
              </InputGroup>
            </FormGroup>

            {returnedObject("module")}
          </Col>

          <Col md={6}>
            <h5 className="p-b-m p-t-l">Configs</h5>
            <FormGroup>
              <Label>Config ID</Label>
              <InputGroup>
                <CustomInput
                  name="configId"
                  value={configInputs.configId}
                  onChange={onConfigInputsChange}
                  placeholder="650c32e149ab54f46198153e"
                />
                <CustomButton
                  color="primary"
                  onClick={async (_) => await onGetObjectClick("config")}
                >
                  Find
                </CustomButton>
                <CustomButton
                  color="danger"
                  onClick={async (_) => await onDeleteObjectClick("config")}
                >
                  Delete
                </CustomButton>
              </InputGroup>
            </FormGroup>

            {returnedObject("config")}
          </Col>
        </Row>
      </section>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  settings: state.settings,
});

export default connect(mapStateToProps, {
  postOrg,
  deleteObject,
  getObject,
  getOrg,
  putSettings,
})(AdminPage);
