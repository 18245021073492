///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/group
export const getGroupsSuccess = groups => ({ type: types.GET_GROUPS_SUCCESS, groups });
export const getGroupsFailure = errorMessage => ({ type: types.GET_GROUPS_FAILURE, errorMessage });
export const getGroups = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/group` : `${resolveHost()}/api/group${endpoint}`);

    dispatch(getGroupsSuccess(res.data));
  } catch (e) {
    dispatch(getGroupsFailure(e.message));
  }
};

// POST /api/group
export const postGroupSuccess = group => ({ type: types.POST_GROUP_SUCCESS, group });
export const postGroupFailure = errorMessage => ({ type: types.POST_GROUP_FAILURE, errorMessage });
export const postGroup = data => async dispatch => {
  try {
    dispatch(setAlert('Creating the group...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/group`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_GROUP_SUCCESS));
    dispatch(postGroupSuccess(res.data.group));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.POST_GROUP_FAILURE));
    dispatch(postGroupFailure(e.message));
  }
};

// PUT /api/group
export const putGroupSuccess = group => ({ type: types.PUT_GROUP_SUCCESS, group });
export const putGroupFailure = errorMessage => ({ type: types.PUT_GROUP_FAILURE, errorMessage });
export const putGroup = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the group...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/group/${data.groupId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_GROUP_SUCCESS));
    dispatch(putGroupSuccess(res.data.group));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_GROUP_FAILURE));
    dispatch(putGroupFailure(e.message));
  }
};

// DELETE /api/group
export const deleteGroupSuccess = group => ({ type: types.DELETE_GROUP_SUCCESS, group });
export const deleteGroupFailure = errorMessage => ({ type: types.DELETE_GROUP_FAILURE, errorMessage });
export const deleteGroup = groupId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the group...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/group/${groupId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_GROUP_SUCCESS));
    dispatch(deleteGroupSuccess(res.data.group));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.DELETE_GROUP_FAILURE));
    dispatch(deleteGroupFailure(e.message));
  }
};
