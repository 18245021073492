/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////////SETTINGS DEFAULT STATE
const settingsDefaultState = {};
/////////////////////////////////////////////////////////////////////////////SETTINGS REDUCER
const settings = (state = settingsDefaultState, action) => {
  switch (action.type) {
    case types.PUT_SETTINGS_SUCCESS:
    case types.GET_SETTINGS_SUCCESS:
      return { ...state, ...action.settings };

    case types.PUT_SETTINGS_FAILURE:
    case types.GET_SETTINGS_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default settings;
