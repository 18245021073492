///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/node
export const getNodesSuccess = nodes => ({ type: types.GET_NODES_SUCCESS, nodes });
export const getNodesFailure = errorMessage => ({ type: types.GET_NODES_FAILURE, errorMessage });
export const getNodes = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/node` : `${resolveHost()}/api/node${endpoint}`);

    dispatch(getNodesSuccess(res.data));
  } catch (e) {
    dispatch(getNodesFailure(e.message));
  }
};

// POST /api/node
export const postNodeSuccess = node => ({ type: types.POST_NODE_SUCCESS, node });
export const postNodeFailure = errorMessage => ({ type: types.POST_NODE_FAILURE, errorMessage });
export const postNode = data => async dispatch => {
  try {
    dispatch(setAlert('Creating the node...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/node`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_NODE_SUCCESS));
    dispatch(postNodeSuccess(res.data.node));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.POST_NODE_FAILURE));
    dispatch(postNodeFailure(e.message));
  }
};

// PUT /api/node
export const putNodeSuccess = node => ({ type: types.PUT_NODE_SUCCESS, node });
export const putNodeFailure = errorMessage => ({ type: types.PUT_NODE_FAILURE, errorMessage });
export const putNode = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the node...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/node/${data.nodeId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_NODE_SUCCESS));
    dispatch(putNodeSuccess(res.data.node));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_NODE_FAILURE));
    dispatch(putNodeFailure(e.message));
  }
};

// DELETE /api/node
export const deleteNodeSuccess = node => ({ type: types.DELETE_NODE_SUCCESS, node });
export const deleteNodeFailure = errorMessage => ({ type: types.DELETE_NODE_FAILURE, errorMessage });
export const deleteNode = nodeId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the node...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/node/${nodeId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_NODE_SUCCESS));
    dispatch(deleteNodeSuccess(res.data.node));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.DELETE_NODE_FAILURE));
    dispatch(deleteNodeFailure(e.message));
  }
};
