///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse } from '@ibiliaze/reactstrap';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ObjectListItemButtons from '../buttons/ObjectListItemButtons';
//////////////////////////////////////////////////////////////////////////////////////////

const ModulesListItem = ({ auth, module, deleteModule }) => {
  // State
  const [isOpen, setIsOpen] = useState(false);

  // Toggle functions
  const toggle = _ => setIsOpen(!isOpen);

  // History
  const history = useNavigate();

  // onClick functions
  const onEditClick = _ => history(`/modules/edit/${module._id}`);

  // JSX
  return (
    <div key={module._id} className='list-item'>
      <h6 onClick={onEditClick} className='text-button'>
        {module.moduleName}
      </h6>
      <div className='list-item__p1'>{module._id}</div>
      <br />
      <div className='list-item__p1'>Creator: {module?.creator}</div>
      <div onClick={toggle} className='text-button'>
        expand
      </div>
      <Collapse isOpen={isOpen}>
        <hr />
        <ObjectListItemButtons auth={auth} object={module} objectType='module' deleteObject={deleteModule} />
      </Collapse>
    </div>
  );
};

export default ModulesListItem;
