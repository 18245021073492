export const resolveHost = () => {
  /**
   * This function will determine if the app is running in PRODUCTION mode
   * If so, the origin URL will be https://nodeconfig.com.
   * Else, it will be http://192.168.0.131:5000 or http://localhost:5000.
   */

  return !!process.env.REACT_APP_ENV && !!process.env.REACT_APP_ENV.includes('production')
    ? process.env.REACT_APP_API_URL
    : process.env.REACT_APP_API_DEV_URL;
};
