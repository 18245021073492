///////////////////////////////////////////////////////////////////////////////////MODULES
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
//////////////////////////////////////////////////////////////////////////////////REDUCERS
import auth from '../reducers/auth';
import alert from '../reducers/alert';
import sites from '../reducers/sites';
import groups from '../reducers/groups';
import nodes from '../reducers/nodes';
import configs from '../reducers/configs';
import modules from '../reducers/modules';
import settings from '../reducers/settings';
//////////////////////////////////////////////////////////////////////////////////////////

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;
const configureStore = () => {
  const store = createStore(
    combineReducers({ auth, alert, sites, groups, nodes, configs, modules, settings }),
    composeEnhancers(applyMiddleware(thunk))
  );

  return store;
};

export default configureStore;
