///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Spinner } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import GroupContext from "../../context/ObjectContext";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postGroup, putGroup } from "../../actions/groups";
import { getConfigs } from "../../actions/configs";
import apiRequest from "../../utils/httpRequest";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import GroupsFormInputs from "./GroupsFormInputs";
import { GroupHowto } from "../layout/Howto";
//////////////////////////////////////////////////////////////////////////////////////////

const GroupsForm = ({ auth, configs, getConfigs, postGroup, putGroup }) => {
  // State
  const [groupId, setGroupId] = useState("");
  // For inputs
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [assignedConfigs, setAssignedConfigs] = useState([]);

  // History
  const navigate = useNavigate();

  // onChange functions
  const onGroupNameChange = (e) => setGroupName(e.target.value);
  const onDescriptionChange = (e) => setDescription(e.target.value);
  const onAssignedConfigsChange = (e) => setAssignedConfigs(e); // from react-select

  // Lifecycle hooks
  useEffect(() => {
    const edit = window.location.pathname.replace("/groups/edit", "");
    !!edit && setGroupId(edit.replace("/", ""));

    const req = async () => {
      try {
        await getConfigs("?select=configName");

        if (!!edit) {
          const response = await apiRequest({
            endpoint: "group",
            method: "GET",
            params: edit,
          });
          if (response.isError) throw new Error(response.message);
          setGroupName(response?.groupName);
          setDescription(response?.description);
          setAssignedConfigs(
            response?.assignedConfigs
              ? response?.assignedConfigs.map((config) => ({
                  value: config,
                  label: config,
                }))
              : null
          );
        }
      } catch (e) {
        setGroupId("");
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    if (!auth.isAuthenticated) return navigate.push("/auth");

    const req = groupId ? putGroup : postGroup;
    await req({
      groupId: groupId ? groupId : undefined,
      groupName: groupId ? undefined : groupName,
      description,
      assignedConfigs:
        assignedConfigs !== "" && assignedConfigs
          ? assignedConfigs.map((assignedConfig) => assignedConfig.value)
          : [],
    });
  };

  // JSX
  return (
    <GroupContext.Provider
      value={{
        groupId,
        configs,
        groupName,
        description,
        assignedConfigs,
        onGroupNameChange,
        onDescriptionChange,
        onAssignedConfigsChange,
      }}
    >
      <div className="below-header">
        <h1 className="t-align-c no-m">Group Editor</h1>
        <section className="page p-b-m p-t-m" style={{ minHeight: "100vh" }}>
          <Form onSubmit={onSubmit}>
            {groupId && !groupName ? (
              <Spinner color="primary" className="object-list__spnr" />
            ) : (
              <GroupsFormInputs />
            )}
          </Form>
          <GroupHowto />
        </section>
      </div>
    </GroupContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  message: state.message,
  auth: state.auth,
  configs: state.configs,
});

export default connect(mapStateToProps, { getConfigs, postGroup, putGroup })(
  GroupsForm
);
