///////////////////////////////////////////////////////////////////////////////////MODULES
import { api } from '../utils/httpRequest';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
//////////////////////////////////////////////////////////////////////////////////////////

// GET /api/config
export const getConfigsSuccess = configs => ({ type: types.GET_CONFIGS_SUCCESS, configs });
export const getConfigsFailure = errorMessage => ({ type: types.GET_CONFIGS_FAILURE, errorMessage });
export const getConfigs = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/config` : `${resolveHost()}/api/config${endpoint}`);

    dispatch(getConfigsSuccess(res.data));
  } catch (e) {
    dispatch(getConfigsFailure(e.message));
  }
};

// POST /api/config
export const postConfigSuccess = config => ({ type: types.POST_CONFIG_SUCCESS, config });
export const postConfigFailure = errorMessage => ({ type: types.POST_CONFIG_FAILURE, errorMessage });
export const postConfig = data => async dispatch => {
  try {
    dispatch(setAlert('Creating the config...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/config`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.POST_CONFIG_SUCCESS));
    dispatch(postConfigSuccess(res.data.config));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.POST_CONFIG_FAILURE));
    dispatch(postConfigFailure(e.message));
  }
};

// PUT /api/config
export const putConfigSuccess = config => ({ type: types.PUT_CONFIG_SUCCESS, config });
export const putConfigFailure = errorMessage => ({ type: types.PUT_CONFIG_FAILURE, errorMessage });
export const putConfig = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the config...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/config/${data.configId}`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_CONFIG_SUCCESS));
    dispatch(putConfigSuccess(res.data.config));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.PUT_CONFIG_FAILURE));
    dispatch(putConfigFailure(e.message));
  }
};

// DELETE /api/config
export const deleteConfigSuccess = config => ({ type: types.DELETE_CONFIG_SUCCESS, config });
export const deleteConfigFailure = errorMessage => ({ type: types.DELETE_CONFIG_FAILURE, errorMessage });
export const deleteConfig = configId => async dispatch => {
  try {
    dispatch(setAlert('Deleting the config...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/config/${configId}`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_CONFIG_SUCCESS));
    dispatch(deleteConfigSuccess(res.data.config));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.res ? e.res.status : 500, types.DELETE_CONFIG_FAILURE));
    dispatch(deleteConfigFailure(e.message));
  }
};
