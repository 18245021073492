/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////////NODES DEFAULT STATE
const nodesDefaultState = [];
/////////////////////////////////////////////////////////////////////////////NODES REDUCER
const nodes = (state = nodesDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_NODES_SUCCESS:
      return [...action.nodes];
    case types.GET_NODES_FAILURE:
      return [...state];

    // POST request
    case types.POST_NODE_SUCCESS:
      return [...state, action.node];
    case types.POST_NODE_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_NODE_SUCCESS:
      return state.map(node => {
        if (node._id === action.node._id) {
          return { ...node, ...action.node };
        } else {
          return node;
        }
      });
    case types.PUT_NODE_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_NODE_SUCCESS:
      return state.filter(node => node._id !== action.node._id);
    case types.DELETE_NODE_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default nodes;
