///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { Banner as BannerRS } from '@ibiliaze/reactstrap';
//////////////////////////////////////////////////////////////////////////////////////////

const Banner = ({ settings }) => (
  <div className='p-b-s'>
    {!!settings && settings?.showBanner && <BannerRS title={settings?.bannerHeader} text={settings?.bannerBody} />}
  </div>
);

const mapStateToProps = state => ({ settings: state.settings });

export default connect(mapStateToProps)(Banner);
