///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import {
  CustomInput,
  Label,
  FormGroup,
  CustomButton,
  UncontrolledTooltip,
  Popover,
  PopoverBody,
  PopoverHeader,
  Spinner,
  Alert,
} from '@ibiliaze/reactstrap';
/////////////////////////////////////////////////////////////////////////////////////ICONS
import * as FiIcons from '../../utils/icons';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from '../../utils/httpRequest';
//////////////////////////////////////////////////////////////////////////////////////////

const PublishButton = ({ parameters, objectType }) => {
  // State
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [inputs, setInputs] = useState({ objectName: '', creator: '' });
  const [clientMessage, setClientMessage] = useState({ text: '', loading: false, color: 'secondary' });

  // Toggle functions
  const toggle = _ => setPopoverOpen(!popoverOpen);

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onPublishClick = _ => {
    const postObject = async () => {
      try {
        setClientMessage(c => ({ ...c, loading: true }));
        await new Promise(resolve => setTimeout(resolve, 1000));
        const res = await apiRequest({
          endpoint: 'community',
          method: 'POST',
          data: { ...inputs, objectType, object: parameters },
        });
        if (res.isError) throw new Error(res.message);
        setClientMessage(c => ({ text: 'Object published successfully', color: 'success', loading: false }));
      } catch (e) {
        setClientMessage(c => ({
          text: e?.message || 'Failed to publish object',
          color: 'danger',
          loading: false,
        }));
      }
    };

    postObject();
  };

  // JSX
  return (
    <>
      <CustomButton color='primary' id={`com-${parameters._id}`} outline>
        <FiIcons.FiUsers />
        <UncontrolledTooltip placement='bottom' target={`com-${parameters._id}`}>
          Publish
        </UncontrolledTooltip>
      </CustomButton>
      <Popover
        trigger='legacy'
        isOpen={popoverOpen}
        toggle={toggle}
        placement='right'
        target={`com-${parameters._id}`}
        className='info-button-popover'
      >
        <PopoverHeader>
          Publish Object {clientMessage.loading && <Spinner type='grow' color='primary' size='sm' />}
        </PopoverHeader>
        <PopoverBody>
          <FormGroup>
            <Label>Object Name *</Label>
            <CustomInput
              required
              type='text'
              placeholder='My Module'
              value={inputs.objectName}
              name='objectName'
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <Label>Creator *</Label>
            <CustomInput
              required
              type='text'
              placeholder='@Thanos'
              value={inputs.creator}
              name='creator'
              onChange={onInputsChange}
            />
          </FormGroup>
          {clientMessage.text && (
            <>
              <br />
              <Alert color={clientMessage.color}>{clientMessage.text}</Alert>
            </>
          )}
          <CustomButton color='primary' disabled={clientMessage.loading ? true : false} onClick={onPublishClick}>
            Submit
          </CustomButton>
        </PopoverBody>
      </Popover>
    </>
  );
};

export default PublishButton;
