///////////////////////////////////////////////////////////////////////////////////MODULES
import { useContext } from 'react';
import { FormGroup, CustomInput, Label, Row, Col, Button } from '@ibiliaze/reactstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import SiteContext from '../../context/ObjectContext';
//////////////////////////////////////////////////////////////////////////////////////////

const animatedComponents = makeAnimated();

const SitesFormInputs = () => {
  // Context
  const props = useContext(SiteContext);

  // JSX
  return (
    <>
      <FormGroup>
        <Label>Site Name *</Label>
        <CustomInput
          autoFocus
          required
          disabled={props.siteId ? true : false}
          type='text'
          placeholder='Site Name'
          value={props.siteName}
          onChange={props.onSiteNameChange}
        />
      </FormGroup>

      <FormGroup>
        <Label>Description</Label>
        <CustomInput
          type='textarea'
          placeholder='Description'
          rows={props?.description?.split('\n')?.length || 1}
          value={props.description}
          onChange={props.onDescriptionChange}
        />
      </FormGroup>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label>Department</Label>
            <CustomInput type='text' placeholder='Dev' value={props.department} onChange={props.onDepartmentChange} />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label>Type</Label>
            <CustomInput type='text' placeholder='Business Unit' value={props.type} onChange={props.onTypeChange} />
          </FormGroup>
        </Col>

        <Col md={4}>
          <FormGroup>
            <Label>Floor</Label>
            <CustomInput type='text' placeholder='Floor' value={props.floor} onChange={props.onFloorChange} />
          </FormGroup>
        </Col>
      </Row>

      <FormGroup>
        <Label>Country</Label>
        <Select
          components={animatedComponents}
          closeMenuOnSelect={true}
          isSearchable
          value={props.country ? { value: props.country, label: props.country } : ''}
          onChange={props.onCountryChange}
          options={props.countryOptions}
          className='custom-input'
        />
      </FormGroup>

      <hr />
      <FormGroup>
        <Button type='submit' color='primary'>
          Save
        </Button>
      </FormGroup>
    </>
  );
};

export default SitesFormInputs;
