///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Form, Spinner } from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////CONTEXTS
import ConfigContext from "../../context/ObjectContext";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postConfig, putConfig } from "../../actions/configs";
import { getModules } from "../../actions/modules";
import { getNodes } from "../../actions/nodes";
import { getGroups } from "../../actions/groups";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import apiRequest from "../../utils/httpRequest";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import ConfigsFormInputs from "./ConfigsFormInputs";
import { ConfigHowto } from "../layout/Howto";
//////////////////////////////////////////////////////////////////////////////////////////

const ConfigsForm = ({
  auth,
  nodesState,
  groupsState,
  modulesState,
  postConfig,
  putConfig,
  getModules,
  getNodes,
  getGroups,
}) => {
  // State
  const [configId, setConfigId] = useState("");
  const [module, setModule] = useState(null);
  // inputs
  const [configName, setConfigName] = useState("");
  const [agentId, setAgentId] = useState("");
  const [description, setDescription] = useState("");
  const [version, setVersion] = useState("");
  const [nodes, setNodes] = useState("");
  const [groups, setGroups] = useState("");
  const [moduleName, setModuleName] = useState("");
  const [parameters, setParameters] = useState({});

  // History
  const history = useNavigate();

  // onChange functions
  const onConfigNameChange = (e) => setConfigName(e.target.value);
  const onAgentIdChange = (e) => setAgentId(e.target.value);
  const onDescriptionChange = (e) => setDescription(e.target.value);
  const onVersionChange = (e) => setVersion(e.target.value);
  const onNodesChange = (e) => setNodes(e); // from react-select
  const onGroupsChange = (e) => setGroups(e); // from react-select
  const onModuleNameChange = (e) => setModuleName(e.value);

  // Lifecycle hooks
  useEffect(() => {
    const edit = window.location.pathname.replace("/configs/edit", "");
    !!edit && setConfigId(edit.replace("/", ""));

    const req = async () => {
      try {
        await getModules(); // we need full module objects
        await getNodes("?select=nodeName");
        await getGroups("?select=groupName");

        if (!!edit) {
          const response = await apiRequest({
            endpoint: "config",
            method: "GET",
            params: edit,
          });
          if (response.isError) throw new Error(response.message);
          setConfigName(response?.configName);
          setAgentId(response?.agentId);
          setDescription(response?.description);
          setVersion(response?.version);
          setGroups(
            response?.groups
              ? response?.groups.map((group) => ({
                  value: group,
                  label: group,
                }))
              : null
          );
          setNodes(
            response?.nodes
              ? response?.nodes.map((node) => ({ value: node, label: node }))
              : null
          );
          setModuleName(response?.moduleName);
          setParameters(response?.parameters);
        }
      } catch (e) {
        setConfigId("");
      }
    };
    req(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      const response = modulesState.find(
        (module) => module.moduleName === moduleName
      );
      if (!response) throw new Error();
      setModule(response);
    } catch (e) {} // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleName]);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    if (!auth.isAuthenticated) return history("/auth");
    const req = configId ? putConfig : postConfig;
    await req({
      configId: configId ? configId : undefined,
      configName: configId ? undefined : configName,
      agentId,
      description,
      version,
      nodes: nodes !== "" && nodes ? nodes.map((node) => node.value) : [],
      groups: groups !== "" && groups ? groups.map((group) => group.value) : [],
      moduleName,
      parameters,
    });
  };

  // JSX
  return (
    <ConfigContext.Provider
      value={{
        configId,
        nodesState,
        groupsState,
        modulesState,
        configName,
        agentId,
        description,
        version,
        nodes,
        groups,
        parameters,
        moduleName,
        module,
        onConfigNameChange,
        onAgentIdChange,
        onDescriptionChange,
        onVersionChange,
        onNodesChange,
        onGroupsChange,
        onModuleNameChange,
        setParameters,
      }}
    >
      <div className="below-header">
        <h1 className="t-align-c no-m">Config Editor</h1>
        <section className="page p-b-m p-t-m" style={{ minHeight: "100vh" }}>
          <Form onSubmit={onSubmit}>
            {configId && !configName ? (
              <Spinner color="primary" className="object-list__spnr" />
            ) : (
              <ConfigsFormInputs />
            )}
          </Form>
          <ConfigHowto />
        </section>
      </div>
    </ConfigContext.Provider>
  );
};

const mapStateToProps = (state) => ({
  nodesState: state.nodes,
  groupsState: state.groups,
  modulesState: state.modules,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  postConfig,
  putConfig,
  getModules,
  getNodes,
  getGroups,
})(ConfigsForm);
