/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
///////////////////////////////////////////////////////////////////////SITES DEFAULT STATE
const sitesDefaultState = [];
/////////////////////////////////////////////////////////////////////////////SITES REDUCER
const sites = (state = sitesDefaultState, action) => {
  switch (action.type) {
    // GET request
    case types.GET_SITES_SUCCESS:
      return [...action.sites];
    case types.GET_SITES_FAILURE:
      return [...state];

    // POST request
    case types.POST_SITE_SUCCESS:
      return [...state, action.site];
    case types.POST_SITE_FAILURE:
      return [...state];

    // PUT request
    case types.PUT_SITE_SUCCESS:
      return state.map(site => {
        if (site._id === action.site._id) {
          return { ...site, ...action.site };
        } else {
          return site;
        }
      });
    case types.PUT_SITE_FAILURE:
      return [...state];

    // DELETE request
    case types.DELETE_SITE_SUCCESS:
      return state.filter(site => site._id !== action.site._id);
    case types.DELETE_SITE_FAILURE:
      return [...state];

    default:
      return state;
  }
};

export default sites;
